export const enLang = {
  cultureCodeLanguage: 'en-GB',
  languageKey: 'en',
  icon: 'gb',
  translates: {
    noContent: 'No elements in the list',
    LANG_selectLanguage: 'Select a language',
    LANG_es: 'Spanish',
    LANG_en: 'English',
    LANG_pt: 'Portuguese',

    loading: 'Loading',
    LOGIN_email: 'email',
    LOGIN_password: 'password',
    LOGIN_confirmPassword: 'repeat the password',
    LOGIN_change: 'change',
    LOGIN_login: 'enter',
    LOGIN_recoverPassword: 'recover password',
    LOGIN_recoverMessage: 'Introduce your email address and we will send you instructions to reset your password.',
    LOGIN_recover: 'recover',
    LOGIN_backToLogin: 'back',
    LOGIN_emailHasBeenSend: 'The instructions to reset your password have been sent to you via email.',

    FORM_VALIDATION_EMAIL_VALIDATION_ERROR: 'The email format is not valid',
    FORM_VALIDATION_EMAIL_VALIDATION_ERROR_ALT: 'The email format is not valid',
    FORM_VALIDATION_BUDGET_NUMBER_MASK_ERROR: 'The format must be:  999999-999999',
    FORM_VALIDATION_MAX_LENGTH: 'The maximum field length is:',
    FORM_VALIDATION_MIN_LENGTH: 'The minimum field length is:',
    FORM_VALIDATION_REQUIRED: 'Required field',
    FORM_VALIDATION_PATTERN: 'The format is incorrect',
    FORM_VALIDATION_MIN: 'The minimum value is:',
    FORM_VALIDATION_MAX: 'The maximum value is:',
    FORM_VALIDATION_NOT_MATCH: 'the fields do not match',
    FORM_VALIDATION_additionHigherThan: 'Sum is more than 100%',
    FORM_VALIDATION_WRONG_INPUT: 'Your password must have at least 8 characters and contain a combination of lower case and upper case letters, numbers and special characters (for example, ! # $).',
    FORM_VALIDATION_NOT_SAME: 'the fields do not match',
    FORM_VALIDATION_wrongCredentials: 'Incorrect username or password',
    FORM_VALIDATION_MAX_ADDITION: 'The total is more than:',

    MENU_profile: 'my profile',
    MENU_comparables: 'comparables',
    MENU_profileData: 'my data',
    MENU_personalData: 'my data',
    MENU_documents: 'documents',
    MENU_invoices: 'invoices',
    MENU_orders: 'assignments',
    MENU_exchange: 'opportunities',
    MENU_requests: 'collaborations',
    MENU_contactUs: 'call us',
    MENU_how_it_works: 'How does it work?',
    MENU_logOut: 'end session',
    MENU_schedule: 'Schedule',
    MENU_debug: 'debug',
    MENU_updateDocumentationAndIncompatibilitiesMessage: 'Remember your obligation to keep your documentation and list of incompatibilities up to date, pursuant to Article 13 of Royal Decree 775/1997.',
    MENU_absences: 'availability / absences',

    APPRAISER_pageTitle: 'my data',
    APPRAISER_personalData: 'my data',
    APPRAISER_preferences: 'preferences',
    APPRAISER_incompatibilities: 'incompatibilities',
    APPRAISER_firstName: 'name',
    APPRAISER_lastName: 'surnames',
    APPRAISER_nationalIdentityDocument: 'ID document',
    APPRAISER_phoneNumber: 'telephone',
    APPRAISER_degree: 'qualification',
    APPRAISER_edit: 'edit',
    APPRAISER_maxDistance: 'maximum distance',
    APPRAISER_receiveNotifications: 'I want to receive notifications',
    APPRAISER_notificationPush: 'push notifications',
    APPRAISER_notificationByEmail: 'notifications by email',
    APPRAISER_savePreferences: 'save preferences',
    APPRAISER_preferencesMessage: 'Configure your preferences to receive the latest opportunities!',
    APPRAISER_personalDataSection: 'personal data',
    APPRAISER_notificationsSection: 'notifications',
    APPRAISER_changePassword: 'change password',
    APPRAISER_password: 'password',
    APPRAISER_confirmPassword: 'confirm password',
    APPRAISER_visits: 'Visits',
    APPRAISER_deadlineDate: 'Deadline',

    APPRAISER_INVOICE_DATA_title: 'invoicing details',
    APPRAISER_INVOICE_DATA_legalName: 'name or company name',
    APPRAISER_INVOICE_DATA_nationalIdentityDocument: 'Tax ID No.',
    APPRAISER_INVOICE_DATA_bankAccount: 'bank account',
    APPRAISER_INVOICE_DATA_streetType: 'street',
    APPRAISER_INVOICE_DATA_streetName: 'street name',
    APPRAISER_INVOICE_DATA_streetNumber: 'number',
    APPRAISER_INVOICE_DATA_locality: 'town/city',
    APPRAISER_INVOICE_DATA_province: 'province',
    APPRAISER_INVOICE_DATA_postalCode: 'post code',
    APPRAISER_INVOICE_DATA_country: 'country',
    APPRAISER_INVOICE_DATA_portal: 'entrance',
    APPRAISER_INVOICE_DATA_stairs: 'staircase',
    APPRAISER_INVOICE_DATA_gate: 'door',
    APPRAISER_INVOICE_DATA_irpfPercentage: 'income tax withholding % in invoice',
    APPRAISER_INVOICE_DATA_billingTaxType: 'tax type',
    APPRAISER_INVOICE_DATA_ivaPercentage: 'tax',

    LOCATION_country: 'country',
    LOCATION_province: 'province',
    LOCATION_locality: 'town/city',
    LOCATION_streetNotExists: 'street cannot be found on the street map',
    LOCATION_streetType: 'street type',
    LOCATION_streetName: 'street name',
    LOCATION_streetNumber: 'street number',
    LOCATION_postalCode: 'post code',
    LOCATION_portal: 'entrance',
    LOCATION_stairs: 'staircase',
    LOCATION_floor: 'floor',
    LOCATION_gate: 'door',
    LOCATION_locationObservations: 'remarks',
    LOCATION_block: 'block',

    MESSAGES_successPOST: 'added correctly',
    MESSAGES_successPUT: 'edited correctly',
    MESSAGES_errorPUT: 'Error editing',
    MESSAGES_successDELETE: 'Deleted correctly',
    MESSAGES_errorDELETE: 'Error deleting',
    MESSAGES_successUploadFile: 'File uploaded correctly',
    MESSAGES_errorUploadFile: 'Error uploading the file',
    MESSAGES_errorPOSTLIST: 'Error displaying the list',
    MESSAGES_successConfirm: 'Confirmed correctly',
    MESSAGES_errorConfirm: 'Error confirming',
    MESSAGES_successReject: 'Rejected correctly',
    MESSAGES_errorReject: 'Error rejecting',
    MESSAGES_requiredField: 'Required fields missing',
    MESSAGES_the_action_was_successful: 'The action has been performed correctly',
    MESSAGES_successful: 'Successful',
    MESSAGES_error: 'error',
    MESSAGES_errorPOST: 'Error adding',

    ACTIONS_cancel: 'Cancel',
    ACTIONS_add: 'Add',
    ACTIONS_edit: 'Edit',
    ACTIONS_delete: 'Delete',
    ACTIONS_download: 'Download',
    ACTIONS_done: 'Done',
    ACTIONS_back: 'Back',
    ACTIONS_back_list: 'Back to list',
    ACTIONS_save: 'Save',
    ACTIONS_skip: 'Skip',
    ACTIONS_confirm: 'Confirm',
    ACTIONS_close: 'Close',
    ACTIONS_reset: 'Reset',
    ACTIONS_reject: 'rejected',
    ACTIONS_accept: 'Accept',
    ACTIONS_continue: 'Continue',
    ACTIONS_next: 'Next',
    ACTIONS_save_selection: 'Save selection',

    INCOMPATIBILITIES_firstName: 'name',
    INCOMPATIBILITIES_lastName: 'surnames',
    INCOMPATIBILITIES_nationalIdentityDocument: 'ID document',
    INCOMPATIBILITIES_familyRelationship: 'relationship',
    WITNESS_OwnComparables: 'Valum comparables',

    APPRAISAL_BASIC_FILTERS: {
      recordIdContains: 'Case number',
      executionToolId: 'Internal reference number',
      externalRecordId: 'External reference number',
    },
    APPRAISAL_STATUS_FILTERS: {
      currentWorkflowSteps: 'status',
      purposes: 'Purpose',
      visitTypes: 'Type of visit',
    },
    APPRAISAL_LIMIT_DATES_FILTERS: {
      scheduleVisitLimit: 'Deadline for scheduling visit',
      startVisitLimit: 'Deadline for starting visit',
      finishVisitLimit: 'Deadline for completing visit',
      sentReportLimit: 'Deadline for sending report',
    },
    APPRAISAL_INCIDENTS_FILTERS: {
      withIncidentsNotResolved: 'With unresolved incidents',
      withIncidents: 'With incidents',
    },
    APPRAISAL_INVOICE_FILTERS: {
      isInConfirmedInvoice: 'Invoice confirmed',
    },
    EXCHANGE_FILTERS: {
      distanceBetweenPointMaxMeters: 'Maximum distance',
      exchangeMinFee: 'Minimum fee',
      exchangeFinishMaxDateTime: 'Deadline for sending report',
    },
    FILTERS: {
      basicFilters: 'Basic filters',
      exchangeFilters: 'Opportunities filters',
      appraisalState: 'Appraisal status',
      dateLimits: 'Date limit',
      incidents: 'Incidents',
      invoice: 'Invoice',
      addedDate: 'Creation date',
      apply: 'Apply',
      reset: 'Reset',
      requestState: 'Request status',
      typologyFilters: 'Type',
      locationFilters: 'Location',
      featuresFilters: 'Features',
      appraisal: 'Appraisal',
    },
    DATES: {
      from: 'From',
      to: 'To',
      visitScheduleDeadlineDateTime: 'scheduling deadline',
      visitScheduleDateTime: 'visit date',
      appraiserAllDataSentDeadlineDateTime: 'deadline for sending report',
      addedDateTime: 'added',
      solvedDateTime: 'resolved',
      expirationDateTime: 'expiry',
      date: 'date',
      allDay: 'any time of the day',
      hour: 'time',
      chooseADate: 'Choose a date',
      chooseOtherDate: 'Or specify new date for the visit',
      confirmDate: 'Confirm date and time',
      addProposalDate: 'Add possible date for the visit',
      obtainedDatetime: 'Obtained date',
    },

    APPRAISAL_INCIDENTS_FILTER: {
      withIncidentsNotResolved: 'With incidences unresolved',
      withIncidents: 'With incidences',
    },

    APPRAISALS: 'assignments',
    APPRAISAL: {
      searchResult: 'Search result',
      addIncident: 'Add incident',
      addComment: 'Add comment',
      editComment: 'Edit comment',
      finished: 'Completed',
      sentToRevision: 'Sent for review',
      distance: 'Distance',
      expiresAt: 'Expires on',
      lastOrders: 'Recent assignments',
      scheduleVisitPending: 'Pending scheduling',
      visitPending: 'visit pending',
      visitPendingFinish: 'Visit pending completion',
      sentPending: 'to be sent',
      rejected: 'rejected',
      incidents: 'incidents',
      detail: 'details',
      reports: 'reports',
      report: 'report',
      board: 'message board',
      info: 'info',
      requestElements: 'assets to be appraised',
      purpose: 'purpose',
      visitType: 'type of visit',
      minimumTypeVisitRequired: 'Type of minimum visit required',
      deadlines: 'deadlines',
      customer: 'customer',
      prescriptor: 'prescriber',
      customers: 'customers',
      prescriptors: 'prescribers',
      contact: 'contact',
      incidentResolution: 'resolution',
      revision: 'Review',
      request: 'Request',
      assets: 'Assets',
      clusters: 'Clusters',
      comment: 'comment',
      incidentDescription: 'Description',
      incidentTypes: 'incident types',
      scheduleVisit: 'Schedule visit',
      editScheduleVisit: 'Edit visit date',
      startVisit: 'Start visit',
      startVisitMessage: 'The visit is about to start. Continue?',
      finishVisit: 'Complete visit',
      finishVisitMessage: 'The visit is about to end. Continue?',
      sendToRevision: 'Send case for review',
      sendToRevisionMessage: 'The case is going to be sent for review. Continue?',
      visitProposedDates: 'Proposed dates for the visit',
      scheduleVisitSuccess: 'Visit scheduled correctly',
      scheduleVisitError: 'Error scheduling visit',
      visitDate: 'Visit date',
      limitScheduleDateTime: 'deadline for scheduling visit',
      limitSendDataDateTime: 'deadline for sending report',
      successSendToRevision: 'Case sent for review correctly',
      successFinishStarted: 'Visit completed correctly',
      successVisitStarted: 'Visit started correctly',
      sureToSelectDate: 'The date selected is outside the deadline.',
      importReport: 'Import report from',
      confirmationHeader: 'Are you sure?',
      confirmationMessage: 'The report has been copied correctly, with the exception of the document and photograph sections. Please review and amend the necessary details and include the updated documentation',
      scheduleDeadLine: 'deadline for scheduling visit',
      exchangeFinish: 'deadline for sending report',
      idufir: 'CRU (IDUFIR)',
      land: 'Ground',
      propertyRegistryName: 'property register',
      volume: 'volume',
      book: 'book',
      paper: 'sheet',
      inscription: 'entry',
      fee: 'fees',
      executionMode: 'execution mode',
      executedByIvForce: 'ivforce',
      executedByExternalTool: 'external tool',
      registeredPropertyNumber: 'Estate',
      section: 'Sheet',
      appraisalOrderType: 'type of assignment',
    },
    APPRAISAL_REPORT: {
      openingDate: 'opening date',
      closingDate: 'closing date',
      lastEdition: 'last edited',
      inProgress: 'In progress',
      closed: 'Closed',
      version: 'Version',
      importAssetStructure: 'Import structure to assets',
      commonData: 'Common report details',
      clusterToValuate: 'Group for appraisal',
      completeForm: 'Fill in form',
      checkAllAssets: 'Select all units',
      clusterValuations: 'Group appraisals',
      valuateTerrains: 'Appraise plots',
      units: 'units',
      selectStep: 'Select a step',
      requiredPercent: 'required',
      completedPercent: 'completed',
      addAsset: 'Add asset',
      seeValuationResume: 'See appraisals summary',
      valuationResume: 'Appraisals summary',
    },
    ASSET_TREE: {
      addAnnex: 'Add attachment',
      duplicate: 'Duplicate',
      delete: 'Delete',
      cancel: 'Cancel',
      makePrincipal: 'Make main',
      extractAsset: 'Export structure',
      deleteAssetConfirm: 'Delete',
      deleteAssetCancel: 'Cancel',
      deleteAssetSuccess: 'Asset deleted correctly',
      deleteAssetError: 'Error deleting asset',
      deleteAsset: 'Delete asset',
      deleteAssetMessage: 'By deleting the asset, you will also delete all associated attachments. Continue?',
      recalculateValuations: 'Recalculate appraisals',
      notCompleted: 'Not completed',
      unComplete: 'Incomplete',
      complete: 'Complete',
      recalculateWorkPlan: 'Recalculate the construction status',

      hasValuations: 'You cannot group this asset as it has associated valuation methods.',
      onlyClusterSameCategory: 'You will not be able to group mixed elements, soils can only be grouped with soils, finished elements, with finished elements and economic exploitations with economic exploitations. Unfinished items cannot be grouped.',
      selectAssetsToCluster: 'Select the units you want to group:',
    },
    STEPS: {
      location: 'Location',
      identification: 'Identification',
      typology: 'Type',
      surfaces: 'Surface areas',
      property_features: 'Property features',
      localityAndEnvironmentReport: 'Town/city, surroundings and market',
      terrainFeatures: 'Nature of the site',
      buildingTerrain: 'Description of the ground',
      buildingFeatures: 'Nature of the building',
      assetFeatures: 'Nature of the property',
      urbanisticSituation: 'Town planning status',
      possession: 'Ownership',
      checks: 'Checks',
      valuations: 'Appraisals',
      conditioningWarningsObservations: 'Conditioning factors, warnings and remarks',
      photos: 'Photos',
      documentation: 'Documentation',
      surfacesAndAssetFeatures: 'Surface areas and nature of the property',
      surface: 'Surface area',
      LocalityEnvironmentAndBuildingFeatures: 'Nature of the building, town/city, surrounding area and market',
      buildingState: 'Construction status',
      environment: 'Surrounding area',
    },
    DOCUMENTS: {
      addDocument: 'Add document',
      document: 'document',
      documents: 'documents',
      expirationDate: 'expiry date',
      addedSuccess: 'The document has been uploaded satisfactorily',
      deletedSucces: 'The document has been deleted satisfactorily',
      deleteThisDocument: 'Are you sure you want to delete this document?',
      expirationDateTime: 'Expiry date',
    },
    error: 'Error',
    REPORT_ASSET_TREE_FILTER: {
      registryNumber: 'Property',
      idufir: 'CRU (IDUFIR)',
      cadastralReference: 'Land registry reference',
      address: 'Address',
      state: 'Status',
      use: 'Use',
      typology: 'Type',
      subTypology: 'Subtype',
    },
    INVOICE: {
      invoices: 'invoices',
      actionSuccess: 'The action has been completed satisfactorily',
      actionError: 'It has not been possible to perform the action',
      rejectInvoice: 'Reject invoice',
      reasonRejectInvoice: 'Please introduce the reason',
      subtotal: 'subtotal',
      total: 'total',
      irpf: 'income tax',
      iva: 'vat',
      taxPercentageText: 'Taxable base at',
      invoiceNumberAlertTitle: 'Please introduce the invoice number',
      invoiceNumber: 'Invoice no.',
    },
    REPORT_ASSET: {
      dataSource: 'Source of data',
      observations: 'Remarks',
      mainElements_Structure: 'Structure',
      mainElements_Deck: 'Roof',
      mainElements_Enclosure: 'Enclosures',
      mainElements_Foundation: 'Foundations',
      constructionYear: 'Year of construction',
      lastRehabilitationYear: 'Year of refurbishment',
      qualityOfBuilding: "Constructions' quality",
      conservationState: 'Conservation status',
      buildingUse: 'Use',
      totalFloorsBellowGround: 'Floors below ground level',
      totalFloorsAboveGround: 'Floors above ground level',
      numberOfBuildingAccesses: 'Building access',
      currentStateOfBuilding: '',
      streetCategory: 'Street category',
      surveillance: 'Surveillance',
      hasSwimmingPool: 'Swimming pool',
      hasSportsZone: 'Sporting area',
      hasGreenArea: 'Green spaces',
      hasElevator: 'Lift',
      totalPlotSurface: 'Plot surface area',
    },
    VALUATIONS: {
      observationsOcupation: 'Remarks in relation to occupancy',
      observationsNetIncome: 'Remarks in relation to net income / Payment estimate information',
      groundRepercussionError: 'Cost method',
      necessaryConstructionExpensesPercentageRequired: 'Fill in "Calculation based on construction progress"\'',
      calculationsInProgressWorkPlanTitle: 'Construction - Costs and expenses',
      constructionCostsTitle: 'Construction costs',
      calculationsInProgressWorkPlanConstructionCostTotal: 'Total construction cost',
      calculationsInProgressWorkPlanProgressPercentage: 'Construction progress %',
      calculationsInProgressWorkPlanConstructionCostCurrent: 'Construction cost - Current',
      calculationsInProgressWorkPlanNecessaryExpenses: 'Necessary costs %',
      calculationsInProgressWorkPlanExpensesProgressPercentage: 'Progress of costs %',
      calculationsInProgressWorkPlanAdoptedNecessaryExpenses: 'Necessary costs adopted %',
      calculationsInProgressWorkPlanNecessaryExpensesCurrent: 'Necessary costs - Current',
      calculationsInProgressWorkPlanManualEditing: 'Edit percentage of progress in costs',
      calculationsInProgressWorkPlanFormMessage: 'The construction values are calculated based on the construction progress percentage. The Cost value is taken from the distribution in the Construction Status tab.',
      calculationsInProgressWorkPlanLink: 'Calculation based on construction progress',
      costMethodRepercussionCalculateMessage: 'If you amend the repercussion entry mode, you will lose the data included to date. Do you want to proceed?',
      summaryTotalValuationsRecalculationMessage: 'The characteristics of the asset have been changed. You must recalculate the appraisals to proceed',
      futureValuationTotalValue: 'Total HET appraisal',
      currentValuationTotalValue: 'Current total appraisal',
      valorationMethodAdopted: 'Appraisal method',
      insuranceByRoyalDecree: 'Insurance as per Royal Decree',
      insuranceByEco: 'Insurance as per ECO',
      valuationTotalValue: 'Total appraisal',
      insuranceValorationTypesDropdown: 'Calculation of insurance value as regards:',
      proposalValue: 'Proposed value',
      documents: 'Documentation',
      totalProposalValue: 'Total proposed value',
      observations: 'Remarks',
      surface: 'Surface',
      selectSurfaceTocontinue: 'Select a surface area to proceed',
      surfaceCommons: 'Constructed surface area with common areas',
      surfaceBuiltWithoutCommons: 'Constructed surface area without common areas',
      surfaceUseful: 'Useful surface area',
      cadastral: 'Land registry',
      checked: 'Checked',
      registered: 'Registry',
      usefulRegistralSurface: 'Registry useful surface area',
      usefulCheckedSurface: 'Checked useful surface area',
      usefulQualifiedSurface: 'Useful surface area qual. certificate',
      ground: 'Ground',
      repercussion: 'Repercussion',
      method: 'Method',
      necessaryExpenses: 'Necessary costs',
      totalMarketValue: 'Total market value',
      construction: 'Construction',
      VRB: 'Gross replacement value',
      editPercentages: 'Edit percentages',
      physicalBuildingDeprecation: 'Physical depreciation of the building',
      physicalElementDeprecation: 'Physical depreciation of the element',
      functionalDeprecation: 'Functional depreciation',
      VRN: 'New replacement value',
      residualStaticCostTitle: 'Simplified static residual',
      residualStaticCostMarketValueUnitPrice: 'Market value ({{currency_per_surfaceUnit}})',
      residualStaticCostConstructionCostUnitPrice: 'Construction cost ({{currency_per_surfaceUnit}})',
      residualStaticCostConstructionExpensesUnitPrice: 'Construction costs ({{currency_per_surfaceUnit}})',
      residualStaticCostFinancialExpensesPercentage: 'Financial expenses (%)',
      residualStaticCostMarketingExpensesPercentage: 'Marketing expenses (%)',
      residualStaticCostDeveloperProfitPercentage: 'Developer profit (%)',
      residualStaticCostKCoeficient: 'K (Coefficient to switch to new)',
      total: 'Total',
      qualifiedSurface: 'Qualified surface area',
      legalMaximumValues: 'Maximum legal values',
      legalMaximumValue: 'Maximum legal value',
      legalMaximumUnitValues: 'Maximum legal unit value',
      legalMaximumTotalValue: 'Total maximum legal value',
      legalMaximumGroundValue: 'Maximum legal ground value',
      groundValueLimit: 'Ground value limit',
      REA_legalMaximum: 'REA (maximum legal)',
      REA_costMethod: 'REA (cost method)',
      valueTypes: 'Types of value',
      moreInfo: 'Further information',
      marketValue: 'Market value',
      contractualData: 'Contractual data',
      contractStartDate: 'Contract start date',
      contractFinishDate: 'Contract end date',
      nextChargeDate: 'Date of next payment',
      economicalData: 'Economic data',
      monthlyGrossIncome: 'Monthly gross income',
      monthlyExpenses: 'Monthly costs',
      monthlyNetIncome: 'Monthly net income',
      updateType: 'Update type',
      updateRatePercent: 'Update rate',
      monthlyEquivalentUpdateRatePercent: 'Monthly equivalent update rate',
      reversionValue: 'Rollback value',
      capitalGainPercent: 'Ground capital gains/losses',
      updatedValues: 'Values updated',
      updatedIncomes: 'Updated income',
      updatedReversion: 'Reversal updated',
      updatedTotal: 'Total updated',
      marketNetIncome: 'Market net income',
      euroYearSuffix: '{{currency}}/year',
      euroMonthSuffix: '{{currency}}/month',
      euroM2MonthSuffix: '{{currency_per_surfaceUnit}}/month',
      activeNetIncome: 'Active net income',
      currentIncome: 'Current net income',
      date: 'Date',
      incomes: 'Income',
      yearly: 'Annual',
      monthly: 'Monthly',
      marketEconomicRentalData: 'Financial market income details',
      homogeinizedValue: 'Approved value',
      deprecatedHomogeinizedValue: 'Approved depreciation value',
      comparable: 'Comparable',
      adjustComparison: 'Adjusted comparison',
      witnesses: 'Witnesses',
      witness: 'Witness',
      witnessList: 'List of witnesses',
      distance: 'Distance',
      surfaceM2: 'Surface area ({{surfaceUnit}})',
      homogeinizedCoefficient: 'Approval Coef.',
      weight: 'Weight',
      marketValueEuroPerM2: 'Market value ({{currency_per_surfaceUnit}})',
      marketValueEuroPerM2Month: 'Market value ({{currency_per_surfaceUnit}}/month)',
      finalValueEuroPerM2: 'Final value ({{currency_per_surfaceUnit}})',
      finalValueEuroPerM2Mont: 'Final value ({{currency_per_surfaceUnit}}/month)',
      noWitnessAdded: 'No witnesses added',
      desoccupation: 'Vacant',
      latePayment: 'Arrears',
      annualCapex: 'Annual Capex',
      netIncome: 'Net income',
      unitaryEuroM2Month: 'Unit ({{currency_per_surfaceUnit}}/month)',
      monthlyEuroMonth: 'Monthly ({{currency}}/month)',
      annualEuroYear: 'Annual ({{currency}}/year)',
      idlenessMonths: 'Months vacant',
      latePaymentMonths: 'Months of arrears',
      IPC: 'CPI',
      clusterData: 'Cluster details',
      name: 'Name',
      adoptedSurface: 'Surface area adopted',
      reformLastYear: 'Year of refurbishment',
      use: 'Use',
      typology: 'Type',
      subtypology: 'Subtype',
      censusSection: 'Census section',
      buildingInfo: 'Building information',
      mainUse: 'Main use',
      constructionYear: 'Year of construction',
      totalLegalMaximumValue: 'Total maximum legal value',
      createCluster: 'Create cluster',
      clustersValuations: 'Cluster appraisal',
      terrainsValuations: 'Plots appraisal',
      clusterItems: 'Cluster units',
      clusterSurface: 'Cluster surface area',
      clusterDeleteMessage: 'Are you sure you want to delete this cluster?',
      errors: {
        title: 'Errors',
        buildingUseRequired: 'Building use missing',
        buildingYearRequired: 'Building year of construction missing',
        costMethodRequired: 'The cost method is necessary',
        residualStaticAbr: 'The static residual method in the cost method must be filled in',
        atLeastOneSurfaceWithValue: 'A value must be inserted for at least one surface area',
        groundRepercussionError: 'Ground repercussion',
        clusterAssetsWithoutSelectedSurfaces: 'It is necessary to add the surfaces in the Surfaces section of each asset and select them in the Units section.',
      },
      recalculateMethod: 'Recalculate methods',
      recalculate: 'Recalculate',
      current: 'Current appraisal',
      future: 'HET appraisal',
      homogeneousCoefficientType: 'Approval type',
      confirmCoeficientChange: 'If you amend the homogenisation type, you will lose the data associated with it to date. Do you want to proceed?',
      marketPriceUnitValue: 'Market value ({{currency_per_surfaceUnit}})',
      constructionRehabilitation: 'Construction / rehabilitation year',
      surfaceTotal: 'Surface ({{surfaceUnit}})',
      elevatorsCount: 'Nº of elevators',
      homogeneousCoefficient: 'Global approval coef.',
      adoptedUnitValue: 'Final value ({{currency_per_surfaceUnit}})',
      situation: 'Situation Coef.',
      orientation: 'Orientation Coef.',
      state: 'Status Coef.',
      height: 'Height Coef.',
      coefSurface: 'Surface Coef.',
      morphology: 'Morphology Coef.',
      polyvalence: 'Multipurpose Coef.',
      accessibility: 'Accessibility Coef.',
      antiquity: 'Age Coef.',
      others: 'Others Coef.',
      header: 'Edit homogenisation coefficients',
      witnessAddedDate: 'Witness creation date',
      witnessAddedDateCreation: 'Creation date',
    },
    TYPOLOGY: {
      hasActiveCultivation: 'Has active cultivation?',
      use: 'use',
      residenceType: '1st/2nd residence',
      typology: 'type',
      subTypology: 'subtype',
      state: 'asset status',
      conservationState: 'conservation status',
      legalSituation: 'legal status',
      hasEnergeticCertificate: 'Does it have an energy certificate?',
      reformLastYear: 'year of refurbishment',
      energeticCertificateGrade: 'types of energy certificates',
      expedient: 'case',
      protectionTimeLimit: 'protection period',
      provisionalQualificationDateTime: 'provisional classification date',
      definitiveQualificationDateTime: 'definitive classification date',
      isVPO: 'Social housing',
      workState: 'Construction status',
      borrowersHabitualResidence: 'Habitual residence of the borrower',
      isEconomicExploitation: 'Is it an Economic Exploitation?',
      economicExploitationObservations: 'Description of the Exploitation',
    },
    SURFACES: {
      surface: 'surface ({{surfaceUnit}})',
      surfaces: 'surface areas',
      cadastralSurfaces: 'land registry',
      totalUsefulSurface: 'Useful ({{surfaceUnit}})',
      totalBuiltSurfaceWithoutCommonSurfaces: 'constructed surface area without common areas ({{surfaceUnit}})',
      totalBuiltSurfaceWithCommonSurfaces: 'constructed surface area with common areas ({{surfaceUnit}})',
      checkedSurfaces: 'checked',
      registeredSurfaces: 'registry',
      observations: 'remarks',
      totalPlotSurface: 'Plot ({{surfaceUnit}})',
      workPlanTotalSurface: 'Total construction surface area ({{surfaceUnit}})',
    },
    NEW_ASSET: {
      addAsset: 'Add asset',
      back: 'Back to list of assets',
      byLocation: 'by address',
      byCadastralReference: 'By land registry reference',
      locateAgain: 'Back to detect',
      locate: 'Detect',
      addManually: 'Enter manually',
      cadastralReference: 'Land registry reference',
      localization: 'location',
      typology: 'type',
      identification: 'identification',
      surfaceAndBuildingInformation: 'asset surface area and information',
      surface: 'surface',
      constructionYear: 'Year of construction',
      chooseACadastralReference: 'Choose a cadastral reference',
      chooseIdealistaComparables: 'Choose the witness you want to use',
    },
    IDENTIFICATION: {
      cadastralReference: 'Land registry reference',
      cadastralReferenceContainerBuilding: "Building's land registry reference",
      idufir: 'CRU (IDUFIR)',
      registeredPropertyNumber: 'Property',
      propertyRegistrationName: 'Property register',
      volume: 'Volume',
      book: 'Book',
      paper: 'Sheet',
      section: 'Section',
      inscription: 'Entry',
      observations: 'Remarks',
    },
    LOCATION_ENVIRONMENT_MARKET: {
      dataSource: 'source of data',
      editAutomaticInformation: 'edit automatic invoicing',
      environmentPersonalOpinion: 'technical opinion on surrounding area of the building',
      environment: 'surrounding area',
      location: 'town/city',
      market: 'market',
      featuresMarket: 'features comparable property market',
      marketMortgageValueDifferences: 'difference in market and mortgage values',
      offer: 'supply',
      demand: 'demand',
      currentPricSaleRental: 'current sale/rental price intervals',
      demandRevaluationExpectations: 'supply-demand and appreciation expectations',
      jobOccupation: 'workplace',
      characteristicAntiquity: 'age of the feature',
      architecturalHomogeneity: 'architectural homogeneity',
      infrastructures: 'infrastructures',
      equipmentAndServices: 'utilities and services',
      communications: 'communications',
      parkings: 'parking',
      buildingDevelopment: 'construction development',
      renovation: 'renovation',
      localityTypeOfCore: 'location type',
      localityPopulation: 'population',
      localityPopulationEvolution: 'recent population evolution',
      localityUnemploymentRate: 'unemployment',
      localityAvgAnnualIncome: 'average annual disposable income per capita',
      censusSectionTypeOfCore: 'location type',
      automaticDataWarning: 'By saving the form, the details will be retrieved base on location, with the details edited manually being lost.',
      environmentDescription: 'Description of the surrounding area',
      localityMainFeatures: 'Main features of the town/city',
      environmentSignificance: 'Meaning of the surrounding area',
      locationComparedToSameMunicipality: 'Location of property compared to others in the same municipality',
      locationComparedToSameZone: 'Location of property compared to others in the same area',
      locationComparedToSameBuildingElements: 'Location of property in the building compared to other elements in the same building',
      pendingInfrastructuresPercentage: 'Percentage of outstanding infrastructures',
      environmentConsolidationDegreePercentage: 'Percentage degree of consolidation of surrounding area',
    },

    APPRAISAL_REQUESTS: {
      requests: 'requests',
      contactUsTitle: 'contributor benefits increase',
      contentTitle: 'your budgets and payments with a single click',
      list1: 'quote your appraisal orders in just two steps',
      list2: 'know the economic proposal immediately',
      list3: 'receive in your email a payment link and the summary of your order',
      list4: 'your client will only have to access the payment link and will be able to choose the method that best suits their preferences: credit card, bank transfer, etc.',
      list5: 'you do not have to worry about anything else. Your order is already underway!',
      footer: 'we help you optimize and make your work even more profitable.',
      contactUs: 'contact us!',
      readAndAcceptTerms: 'I have read and accept',
      generalConditions: 'the general conditions of sale',
      taxError: 'The rate must be greater than or equal to the minimum proposed rate',
      totalPriceWithoutTaxes: 'price (without taxes)',
      withVisit: 'I make a visit',
      withReport: 'run report',
      observations: 'observations',
      isEnterprise: 'is an enterprise',
      name: 'name',
      lastName: 'lastname',
      nationalIdentityDocument: 'DNI/NIE',
      phone: 'phone',
      email: 'email',
      orderType: 'order type',
      purpose: 'purpose',
      visitType: 'type of visit',
      witECO: 'ECO 805/2003',
      address: 'address',
      postalCode: 'postal code',
      province: 'province',
      locality: 'locality',
      assetData: 'property data',
      addAsset: 'add asset',
      impossibleToMap: 'The cadastre information could not be mapped, some data must be filled in manually',
      taxesMessage: '*The proposed rates are editable as long as the edited amount is higher than the proposed one.',
      cancelAddAsset: 'cancel',
      year: 'year',
      priceRange: 'estimated value range',
      taxes: 'rate',
      owner: 'data of the owner of the appraisal',
      ownerDataMessage: 'We remind you that as the applicant for the appraisal, you are responsible for having informed your client that their data will be transferred to Instituto de Valoraciones S.A. in order to execute the contracted service',
      appraisalRequestFeatures: 'assignment characteristics',
      visitProposals: 'Visit Dates',
      invoiceData: 'invoicing data',
      copyOwnerData: 'Copy owner data',
      saveRequests: 'Save Request',
      addRequest: 'add request',
      requestDetail: 'Detail of the request',
      hasAppraisals: 'Has appraisals',
      showAppraisals: 'View Appraisals',
      relatedAppraisalList: 'List of related appraisals',
      cif: 'CIF',
      rateMessage: '* Rates are calculated based on the type and value of the property. Check that the typology identified corresponds to the real one. If not, please enter the property manually.',
      acceptTipologyCommitmentMessage: 'I accept that the budget will be reviewed by the Valuation Institute to verify that the properties included have been correctly identified in terms of their typology. If it is verified that they do not correspond to the real typology, this budget will not be valid and the client must pay the difference to start the order.',
      visitData: 'contact data for the visit',
      visitName: 'first name last name / business name',
      visitPhone: 'phone',
      visitEmail: 'email',
      visitObservations: 'observations',
      hasRequestElements: 'There are missing elements to add to the request',
      requestElementsArrayIsValid: 'Some of the elements are missing data to fill',
      proposedDatesFormArrayIsValid: 'You need to check the possible visit dates',
      purposeSNB: 'Purpose',
    },

    REPORT_ASSET_PROPERTY_FEATURES: {
      installations: 'installations',
      stays: 'stays',
      hasHotWater: 'hot water',
      hasHeating: 'heating',
      hasAirConditioning: 'air conditioning',
      hasGas: 'gas',
      hasPlumbingAndSanitaryEquipment: 'plumbing and sanitary appliances',
      hasIntercom: 'intercom',
      hasSecurity: 'security',
      hasPhone: 'phone',
      hasSanitation: 'sanitation',
      externalWoodWork: 'exterior carpentry',
      facilityObservations: 'observations',
      pavementType: 'pavement type',
      wallType: 'wall type',
      ceilingType: 'ceiling type',
      internalWoodWork: 'interior carpentry',
      addStay: 'add stay',
      editStay: 'edit stay',
      stayType: 'type of stay',
      totalSurface: 'surface',
      generate: 'generate',
      distribution: 'distribution',
      staysResidentialMessage: 'there must be at least one bedroom and one bathroom',
      deleteThisStay: 'Are you sure you want to delete this room?',

      propertySize: 'Property size',
      license: 'License',
      polivalentUse: 'Multipurpose use',
      productAnalysis: 'Product analysis',

      hasEquippedKitchen: 'Equipped kitchen',
      hasBuiltInWardrobes: 'Built-in wardrobes',
      hasAlarmSystem: 'Alarm system',
      hasClosedSecurityCircuit: 'Security closed circuit',
      hotWaterType: 'Hot water',
      heatingType: 'Heating type',

      localFeatures: 'Local characteristics',
      facade: 'Façade (m)',
      depth: 'Background (m)',
      clearanceHeight: 'Free height (m)',
    },

    WITNESS_DETAIL: {
      supplierInfo: 'Supplier information',
      cadastralInformation: 'Cadastral information',
      cadastralReference: 'Cadastral reference',
      cadastralSurface: 'Cadastral surface',
      builtSurface: 'Built surface',
      plotSurface: 'Plot surface',
      features: 'Features',
      offerValue: 'Offer value',
      reductionNegotiation: 'Reduc. / Negotiation',
      offerType: 'Offer type',
      sourceType: 'Source type',
      crawledSource: 'Witness source',
      providerUrl: 'Provider URL',
      contactInfo: 'Contact phone',
      constructionYear: 'Construction year',
      reformLastYear: 'Reform last year',
      qualityOfBuilding: 'Building quality',
      conservationState: 'Conservation state',
      bathroomCount: 'Bathroom count',
      bedroomCount: 'Bedroom count',
      hasPool: 'Pool',
      hasSportsZone: 'Sport zone',
      hasGreenArea: 'Green area',
      hasHeating: 'Heating',
      hasAirConditioning: 'Air conditioning',
      hasElevator: 'Elevator',
      witnessDetail: 'Witness detail',
      hasPaving: 'Paving',
      hasPublicLighting: 'Public lighting',
      hasWaterSupply: 'Water supply',
      hasSanitationNetwork: 'Sanitation Network',
      hasTelephoneNetwork: 'Telephone network',
      hasPipedGasNetwork: 'Piped gas network',
      buildeableSurface: 'Buildable surface',
      observations: 'Observations',
      mainUse: 'Main use',
      hasFlatTerrain: 'Level ground',
      hasWarehouse: 'Warehouse',
      hasActiveCrop: 'Active cultivation',
      hasRolledAccess: 'Road access',
      hasWaterDistribution: 'Water distribution',
    },
    URBAN_SITUATION: {
      dataSource: 'data source',
      currentPlanning: 'current planning',
      description: 'description',
      currentPlanningAdequacy: 'adaptation to current approach?',
      observations: 'observations',
      subjectCorrespondingManagementInitiative: 'Subject to whom the management initiative corresponds',
      modeAndManagementSystem: 'System and management mode',
      urbanBurdens: 'Urban charges (transfers, urbanization costs, obligations acquired, etc.)',
      pendingProcedures: 'Pending procedures for finalist land',
      executionDeadlinesAndConsequences: 'Execution deadlines foreseen for the urban development of the land. Consequences in case of non-compliance ',
      expropiation: 'Expropriation. Status of the expropriation procedure. Legal criteria applicable to its valuation',
      buildability: 'Buildability',
      characteristicUse: 'Characteristic use',
      compatibleUses: 'Compatible uses',
      prohibitedUses: 'Prohibited uses',
      maxHeight: 'Maximum height',
      occupation: 'Occupation',
      setBacks: 'Setbacks',
      minimunPlot: 'Minimum plot',
      exploitation: 'Exploitation',
      scopeSurface: 'Scope surface',
      managment: 'Urban management',
      params: 'Urban parameters',
      urbanisticQualificationAndManagement: 'Urbanistic qualification and management',
      urbanisticParameters: 'Urbanistic Parameters',
      isAdequatedToCurrentPlanning: 'Adequacy to current approach?',
    },

    POSSESSION: {
      occupationState: 'occupation state',
      occupationType: 'occupant',
      hasNonexistenceLeasesDocument: 'document of nonexistence of harangues',
      hasContract: 'Is there a contract?',
      tenureType: 'tenure type',
      name: 'first and last name',
      nationalIdentityDocument: 'DNI / NIF',
      percentage: 'percentage',
      editTenure: 'edit tenure',
      addTenure: 'add tenure',
      deleteMessage: 'Are you sure you want to delete this tenure?',
      tenurePorcentageMessage: 'The sum of the percentages must be 100%',
      requiredField: 'required fields to fill are missing',
      tenuresLength: 'need to add tenures',
      tenureNif: 'DNI/NIF',
    },

    CHECKS: {
      assetChecksDataSource: 'use general data from report',
      MinimumChecks: 'minimum checks',
      ChecksRelatedToAttachedDocumentation: 'Checks regarding attached documentation',
    },

    CONDITIONING_FACTORS: {
      conditions: 'conditions',
      warnings: 'warnings',
      observations: 'observations',
      conditionalType: 'Type of conditioner',
      warningType: 'Warning type',
    },

    TERRAIN_DESCRIPTION_AND_SURFACES: {
      terrainSurfaces: 'terrain surfaces',
      landInfrastructures: 'land infrastructures',
      cadastralSurfaceTotalPlotSurface: 'registry ({{surfaceUnit}})',
      registeredSurfaceTotalPlotSurface: 'cadastral ({{surfaceUnit}})',
      checkedSurfaceTotalPlotSurface: 'checked ({{surfaceUnit}})',
      terrainUse: 'soil grade',
      urbanizationState: 'state of urbanization',
      morphology: 'morphology',
      topography: 'topography',
      observations: 'observations',
      withPaving: 'paving',
      withStreetLighting: 'public lighting',
      withWaterSupply: 'water supply',
      withSanitationNetwork: 'sanitation network',
      withTelephoneNetwork: 'telephone network',
      withPipedGasNetwork: 'centralized gas network',
      infrastructureObservations: 'observations',
      terrainDescription: 'Terrain description',
    },

    TERRAIN_FEATURES: {
      pendingInfraestructure: 'Pending infrastructure',
      workDonePercentage: 'Percentage of work done',
      currentInfraestructure: 'existing infrastructure',
      urbanizationCostsAndPendingCharges: 'urbanization costs and pending charges',
      existingEdifications: 'existing buildings',
      surfaceToDemolish: 'surface to demolish ({{surfaceUnit}})',
      accessibility: 'accessibility',
      groundLevel: 'ground level (Normative ECO / 805/2003)',
      planningDevelopment: 'development of approach',
      managementSystem: 'management system',
      managementPhase: 'management phases',
      urbanCoreProximity: 'proximity to the urban core',
      productToDevelopTypes: 'product to be developed',
      percentageOfValuedArea: 'percentage of the valued scope',
      terrainInformationDataSource: 'data source',
      exploitingAboveGround: 'Exploitation above grade ({{surfaceUnit}})',
    },

    EXCHANGES: {
      list: 'Exchange list',
      assignToMe: 'Asign it to me!',
    },

    REPORT_VISIT_ASSET_SURFACES_FACILITIES_STAYS: {
      surfaces: 'surface',
      facilities: 'facilities',
      stays: 'stays',
      totalUsefulSurface: 'useful ({{surfaceUnit}})',
      totalBuiltSurfaceWithoutCommonSurfaces: 'built without commons ({{surfaceUnit}})',
      totalBuiltSurfaceWithCommonSurfaces: 'built with commons ({{surfaceUnit}})',
    },

    REPORT_VISIT_ASSET_BUILDING_INFO: {
      localityEnvironmentAndMarket: 'locality, environment and market',
      buildingFeatures: 'building characteristics',
      environmentPersonalOpinion: 'technical opinion on the environment of the property',
      mainElements_Structure: 'structure',
      mainElements_Deck: 'deck',
      mainElements_Enclosure: 'enclosures',
      mainElements_Foundation: 'foundation',
      constructionYear: 'year of construction',
      lastRehabilitationYear: 'year of rehabilitation',
      totalPlotSurface: 'sup. plot',
      qualityOfBuilding: 'quality of buildings',
      conservationState: 'conservation status',
      buildingUse: 'use',
      totalFloorsBellowGround: 'No. plants below grade',
      totalFloorsAboveGround: 'No. plants above ground',
      numberOfBuildingAccesses: 'No. accesses to the building',
      streetCategory: 'street category',
      surveillance: 'surveillance',
      hasSportsZone: 'sports zone',
      hasSwimmingPool: 'pool',
      hasGreenArea: 'green areas',
      hasElevator: 'elevator',
      observations: 'observations',
      requiredField: 'required fields to fill are missing',
    },

    INPUT_FILE: {
      errorTitle: 'Error',
      errorMessage: 'Format not supported',
    },
    BooleanQuestionValues_false: 'No',
    BooleanQuestionValues_true: 'Yes',
    REQUEST_FILTERS: {
      id: 'ID',
      containsOwnerFullName: 'Customer name',
      containsOwnerNationalIdentityDocument: 'Customer ID',
    },
    DEBUG: {
      confirm: 'Confirm',
      setDev: 'Set DEV',
      setQA: 'Set QA',
      setPROD: 'Set PROD',
      setPreset: 'Or set a preset endpoint:',
      setEndpoint: 'Set endpoint',
      setCustomEndpoint: 'Set custom endpoint:',
      enpointAdress: 'Endpoint address:',
      cancel: 'Cancel',
    },
    VALUATION_RESUME: {
      version: 'Version',
      element: 'Element',
      unitValue: 'Unit value',
      totalValue: 'Total value {{currency}}',
      subtotal: 'Subtotal',
      total: 'Total',
    },

    GROUP_VALUATE_isVPO: 'It is VPO',
    GROUP_VALUATE_protected: 'Protected',
    GROUP_VALUATE_free: 'Free',
    CLUSTER_TERRAIN: {
      addBuildingHypothesis: 'Add hypothesis',
      editParmaters: 'edit parameters',
      riskPremiumPercent: 'risk premium',
      developerProfitPercent: 'developer profit (%)',
      valorationUnitValue: 'market value',
      weight: 'weight',
      repercussion: 'repercussion',
      editBuildingHypothesis: 'Edit hypothesis',
      editIncome: 'Edit income',
      editExpense: 'Edit expense',
      maxPercentage: 'between 0-100%',
      updatedIncome: 'Updated income',
      updatedExpenses: 'Updated expenses',
      totals: 'Totals',
      buildingHypothesisValuation: 'Hypothesis valuation',
      valuationMethod: 'Valuation method',
      countBuildingElements: 'No. elements to build',
      observations: 'Observations',
      calculationMethod: 'Calculation of method',
      elementSurface: 'Estimated element surface ({{surfaceUnit}})',
      valorrationUnitValueProposed: 'Value proposition ({{currency_per_surfaceUnit}})',
      terrainMethodChange: 'Changing the valuation method will erase all items created from the previous valuation. Continue? ',
      increaseInExternalFinancing: 'Increase external financing (%)',
      updateRate: 'Update rate (%)',
      monthlyRate: 'Tasa act. Mensual eq.',
      yearly: 'Annual',
      biannual: 'Semiannual',
      monthly: 'Monthly',
      increaseInExternalFinancingObservations: 'Value justification',
      groupBy: 'Group by',
      byPeriods: 'by periods',
      assignValueToAll: 'Assign value to all',
      netExpensesUpdated: 'Expenses Act.',
      total: 'Total',
      updated: 'Updated',
      totalUpdated: 'Total Updated',
      totalIncomesExpensesDiff: 'TOTAL (I-G)',
      cashflows_observations: '* It will only be necessary to distribute the Income / Expenses that have been added with the Non-Periodic Typology.',
      assigned: 'Assigned',
      calculateValorationValue: 'Market value calculation',
    },

    BUILDING_STATE: {
      observations: 'Observations',
      adoptedSurface_2: 'Superficie adoptada',
      constructionCostsTotalValue: 'Construction cost {{currency}}',
      recalculateWarning: 'Remember after recalculate you must check assets repartition and save your changes.',
      recalculateBeforeContinue: 'Recalculation is needed before you can edit this form',
      distribution: 'Distribution',
    },

    surfaceCommons: 'Built surface with commons',
    surfaceUseful: 'Useful surface',
    surfaceWithoutCommons: 'Built surface without commons',
    cadastral: 'Cadastral',
    checked: 'Checked',
    registered: 'Registered',

    VALIDATION_MODAL: {
      bodyText: 'I declare that I have read and understood the articles relating to the obligations of secrecy and incompatibilities of the professionals referred to in articles 11 and 13 of RD 775/1997, as well as the <a href="https://dev-migration.iv.solutions/files/users/IV_Reglamento_Interno_de_Conducta_2020.pdf">internal rules of conduct</a>, accepting both for the performance of my professional activity at Instituto de Valoraciones SA. <hr> I undertake to inform to Instituto de Valoraciones of any conflict of interest arising from the performance of my work and to keep my incompatibilities updated.',
      button: 'I ACCEPT',
    },

    CHANGE_TEMPORAL_PASSWORD_recoverMessage: 'Your current password is temporary, for security reasons it must be changed',

    COMPARABLES_FILTERS: {
      workflowItemAssetId: 'Record number',
      offerType: 'Offer type',
      addedMinDateTime: 'Added date',
      addedMaxDateTime: 'Added date',
      bathroomCount: 'Bathroom count',
      bedroomCount: 'Room count',

      hasHotWater: 'Hot water',
      hasHeating: 'Heating',
      hasAirConditioning: 'Air conditioning',
      hasGas: 'gas',
      hasPlumbingAndSanitaryEquipment: 'Plumbing and sanitary equipment',
      hasIntercom: 'pintercom',
      hasSecurity: 'security',
      hasPhone: 'phone',
      hasSanitation: 'sanitation',
      hasSwimmingPool: 'Swimming pool',
      hasSportsZone: 'Sports zone',
      hasGreenArea: 'Green area',
      hasElevator: 'Elevator',
      hasPool: 'pool',

      maxValorationTotalAdoptedSurface: 'Max surface',
      minValorationTotalAdoptedSurface: 'Min surface',
    },

    ASSETS_totalPlotSurface: 'Plot surface ({{surfaceUnit}})',

    numberAbbreviation: 'Nº',
    currentValuationAbbreviation: 'Actual',
    futureValuationAbbreviation: 'H.E.T.',
    visit: 'Visita',
    incidentAbbreviation: 'INC',

    confirmDeleteTitle: 'Confirmar eliminar',
    confirmDeleteMessage: '¿Está seguro de querer eliminar esta valoración?',
    buildingElementChangeValuationMethodMessage: 'Al cambiar el método de valoración se eliminará el anterior método usado',
    noMoreInfo: 'No hay más información',

    REA: {
      maximumLegalDividedByCostMethodSurface: 'Este valor viene dado de el valor máximo legal dividido entre la superficie adoptada en el método de coste',
      surfaceInCostMethod: 'Superficie del método de coste: ',
    },

    EXCHANGE_TOUR: {
      welcome: '¡Bienvenido!',
      configureMaxDistance: ' Empieza configurando la distancia máxima de la que quieres recibir oportunidades',
      preferencesSaved: 'Preferencias guardadas correctamente',
      assetAddress: 'Dirección del activo',
    },
    EXCHANGE: {
      assignAppraisalInExchangeMessage: 'Con la asignación estás asumiendo la ejecución de la valoración por los honorarios y en el plazo indicado.',
      successfulAssigned: 'Asignada correctamente',
      errorAssigned: 'Ha ocurrido un error, inténtelo de nuevo.',
    },

    invoice: 'Factura',

    TOUR: {
      exitTour: 'Skip tour',
      canChangePreferences: 'Remember that you can always change your preferences from PROFILE',
      canFilterExchanges: 'You can filter opportunities by date and rate',
      exchangeMoreInfo: 'Are you interested in learning more about the opportunity?',
      clickInCard: 'Click on the card',
      hurryUpAndAssign: '...or if everything fits, hurry up and Assign it!',
      continueWithYourWork: 'And continue with the usual work process',
    },
    CANDEACTIVATE: {
      pendingChanges: 'There are pending changes',
      pendingChangesMessage: 'You have changes pending to confirm. Are you sure you want to leave this page?',
      yesWithoutSaving: 'Yes, no saving',
      yesSaving: 'Yes, saving',
    },
    WORKPLAN_everyAssetMustHaveAValidSurface: 'All assets must have at least one surface with value',
    EXCHANGE_errorMessage: 'An error has occurred, please try again.',
    EXCHANGE_executionMessage: 'With the assignment you are assuming the execution of the valuation for the fees and within the indicated period.',
    EXCHANGE_assignedRight: 'Assigned correctly',
    COST_METHOD_editFieldsInCurrentValoration: 'The rest of the fields can only be edited from the current Valuation tab',
    VALUATION_LIST_residualDynamicInfo: 'The dynamic residual method will be added from the State of the work chapter',
    VALUATION_LIST_residualDynamicDeleteMessage: 'This method can only be removed by removing the corresponding common data dynamic residual method in the Work status tab',

    CLUSTER: {
      clusterData: 'cluster detail',
      valuations: 'Valuations',
      totalValue: 'Current valuation',
      valuationsSummary: 'valuations summary',
      allValuations: 'All valuations',
      canNotValuate: 'This asset cannot be valued as it belongs to a pool.',
      assetNotHasSurfaces: 'Es necesario añadir las superficies en el apartado de Superficies del activo.',
      surfacesError: 'Faltan superficies en los activos por seleccionar.',
      valuationSurfaceSelectedMessage: 'The selected surface can be modified in Units',
      valuationSurfaceTitle: 'Surface',
      necessarySaveCluster: 'Save is necessary to recalculate the grouping surface.',
      necessarySaveClusterValuationsStep: 'Save in Units is required to recalculate the surface of the cluster.',
      addAsset: 'Add units',
      removeAssetWithParentOrChildrenConfirm: 'Removing this drive will remove the drives linked to it. Remove?',
      removeAssetConfirm: 'You are going to remove this drive. Remove?',
      selectClusterType: 'Select grouping type:',
      selectClusterAssets: 'Select cluster assets',
      minClusterAssets: '* Cluster must consist of at least two assets',
      selectableAssets: 'selectable',
      noSelectableAssets: 'not selectable',
      noItemsSection: 'There are no items',
      noItemsToSelect: 'There are no items that can be part of the grouping. If you consider that you have an asset that you need to group together, you can check if there is an error in the Non-selectable assets part. ',
      isEconomicExploit: 'Economic exploitation',
      isNotEconomicExploit: 'No economic exploitation',
    },

    COST_METHOD_isUnfinishedMessage: 'The adopted surface can only be modified in the Work Status chapter.',
    COST_METHOD_recalulateWorkPlan: 'It is necessary to recalculate the state of the work and assign a surface to this asset.',
    COST_METHOD_totalAdoptedSurface: 'It is necessary to define the surfaces adopted in the Work Status chapter.',

    CLUSTER_DETAIL_canNotRemoveAssets: 'Unable to remove. There must be at least two units per grouping ',

    REPORT_ASSET_report_structure_hasChildrenWithErrors_error: 'It is necessary to review the errors belonging to the annexes to be able to group.',

    CLUSTERS_canNotChangeUseToTerrain: 'This asset belongs to a cluster. If you want to convert it into land, it is necessary to remove it from that grouping ',
    EX_economicDataInfoModalTitle: 'Economic Data Information',

    last_orders_empty: 'Hello {{user}}! You do not have any new orders, if you miss any, check the rest of the states: Visit pending, Pending sending...',
  },
};
