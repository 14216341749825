import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { ASYNC_BUTTON_STATE } from '../../../async-button';
import { ToastrWrapperService } from '@foxeet/utils/services';
import { isNil } from '@foxeet/utils/functions';

@Component({
  selector: 'ui-confirm-modal',
  templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent implements OnInit {
  @Input() hasUserInput = false;
  @Input() showConfirm = true;
  @Input() showCancel = true;
  @Input() showClose = false;

  private callback!: () => any;

  public formGroup: FormGroup;
  public btnState = new BehaviorSubject<ASYNC_BUTTON_STATE>(ASYNC_BUTTON_STATE.IDLE);

  public okLabel = 'confirm';
  public okLoadingLabel = 'confirming';
  public closeLabel = 'cancel';

  constructor(
    @Optional() public dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private _toastrWrapperService: ToastrWrapperService,
    private _formBuilder: FormBuilder,
  ) {
    if (!isNil(this.data.showConfirm)) this.showConfirm = this.data.showConfirm;
    if (!isNil(this.data.showCancel)) this.showCancel = this.data.showCancel;
    if (!isNil(this.data.showClose)) this.showClose = this.data.showClose;

    this.formGroup = this._formBuilder.group({
      userMessage: this._formBuilder.control(null, []),
    });
  }

  ngOnInit() {
    this.callback = this.data.callback;
    if (this.data.okLabel) this.okLabel = this.data.okLabel;
    if (this.data.okLoadingLabel) this.okLoadingLabel = this.data.okLoadingLabel;
    if (this.data.closeLabel) this.closeLabel = this.data.closeLabel;
  }

  confirm() {
    this.btnState.next(ASYNC_BUTTON_STATE.SENDING);
    if (this.data.callback) {
      this.callback()
        .pipe(
          take(1),
          finalize(() => this.btnState.next(ASYNC_BUTTON_STATE.IDLE)),
        )
        .subscribe(
          (result: any) => {
            if (!this.hasUserInput) {
              this._toastrWrapperService.success();
            }
            this.dialogRef.close(result?.body ? result?.body : result ?? true);
          },
          ({ error }) => {
            this._toastrWrapperService.error(error.detail, error.title);
            this.dialogRef.close(null);
          },
        );
    } else {
      this.dialogRef.close(true);
    }
  }
}
