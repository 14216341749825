export enum LicenseeUserRole {
  Appraiser = 'LicenseeUserRole_Appraiser',
}

export const authoritiesDefinition = {
  orders: {
    access: ['LicenseeUserRole_Appraiser'],
    enable: ['LicenseeUserRole_Appraiser'],
    pages: {
      access: ['LicenseeUserRole_Appraiser'],
      enable: ['LicenseeUserRole_Appraiser'],
      list: {
        access: ['LicenseeUserRole_Appraiser'],
        enable: ['LicenseeUserRole_Appraiser'],
        actions: {
          canFilter: ['LicenseeUserRole_Appraiser'],
          canShowLastOrders: ['LicenseeUserRole_Appraiser'],
          canShowVisitPending: ['LicenseeUserRole_Appraiser'],
          canShowPendingToSend: ['LicenseeUserRole_Appraiser'],
          canShowSentToRevision: ['LicenseeUserRole_Appraiser'],
          canShowFinished: ['LicenseeUserRole_Appraiser'],
          canShowRevisions: ['LicenseeUserRole_Appraiser'],
          canShowIncidents: ['LicenseeUserRole_Appraiser'],
        },
      },
      detail: {
        access: ['LicenseeUserRole_Appraiser'],
        enable: ['LicenseeUserRole_Appraiser'],
        actions: {
          canScheduleVisit: ['LicenseeUserRole_Appraiser'],
          canStartVisit: ['LicenseeUserRole_Appraiser'],
          canFinishVisit: ['LicenseeUserRole_Appraiser'],
          canSentToRevision: ['LicenseeUserRole_Appraiser'],
          canAddComment: ['LicenseeUserRole_Appraiser'],
          canAddIncident: ['LicenseeUserRole_Appraiser'],
          canImportReport: ['LicenseeUserRole_Appraiser'],
          canShowDocuments: ['LicenseeUserRole_Appraiser'],
        },
        info: {
          access: ['LicenseeUserRole_Appraiser'],
          enable: ['LicenseeUserRole_Appraiser'],
          actions: {
            canEdit: ['LicenseeUserRole_Appraiser'],
          },
        },
        incidents: {
          access: ['LicenseeUserRole_Appraiser'],
          enable: ['LicenseeUserRole_Appraiser'],
        },
        board: {
          access: ['LicenseeUserRole_Appraiser'],
          enable: ['LicenseeUserRole_Appraiser'],
          actions: {
            canAdd: ['LicenseeUserRole_Appraiser'],
            canReply: ['LicenseeUserRole_Appraiser'],
            canEdit: ['LicenseeUserRole_Appraiser'],
            canDelete: ['LicenseeUserRole_Appraiser'],
          },
        },
        reports: {
          access: ['LicenseeUserRole_Appraiser'],
          enable: ['LicenseeUserRole_Appraiser'],
        },
      },
      documents: {
        access: ['LicenseeUserRole_Appraiser'],
        enable: ['LicenseeUserRole_Appraiser'],
        actions: {
          canAdd: ['LicenseeUserRole_Appraiser'],
          canEdit: ['LicenseeUserRole_Appraiser'],
          canDelete: ['LicenseeUserRole_Appraiser'],
          canSetAsPrintable: ['LicenseeUserRole_Appraiser'],
          canDownload: ['LicenseeUserRole_Appraiser'],
        },
      },
      reports: {
        access: ['LicenseeUserRole_Appraiser'],
        enable: ['LicenseeUserRole_Appraiser'],
        request: {
          access: ['LicenseeUserRole_Appraiser'],
          enable: ['LicenseeUserRole_Appraiser'],
          actions: {
            canImportStructure: ['LicenseeUserRole_Appraiser'],
          },
        },
        addAsset: {
          access: ['LicenseeUserRole_Appraiser'],
          createAsset: {
            access: [],
            enable: [],
            showExternSourceFilter: {
              access: ['LicenseeUserRole_Appraiser'],
            },
            showLocationFilter: {
              access: ['LicenseeUserRole_Appraiser'],
            },
            showCadastralReferenceFilter: {
              access: ['LicenseeUserRole_Appraiser'],
            },
          },
        },
        commonAssets: {
          access: ['LicenseeUserRole_Appraiser'],
          enable: ['LicenseeUserRole_Appraiser'],
          'locality-environment': {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
              canGetAutomaticData: ['LicenseeUserRole_Appraiser'],
            },
          },
          terrainFeatures: {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          'exploitation-description': {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          'rustic-estate-features': {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          'visit-environment': {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          'building-terrain': {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          'building-features': {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          'urbanistic-situation': {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          'market-studies': {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
              canGetAutomaticData: ['LicenseeUserRole_Appraiser'],
            },

            detail: {
              createComparable: {
                access: ['LicenseeUserRole_Appraiser'],
                enable: [],
                showExternSourceFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
                showLocationFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
                showCadastralReferenceFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
              },
              ListComparable: {
                access: ['LicenseeUserRole_Appraiser'],
                enable: [],
                showSourceFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
                showLocationFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
                showCadastralReferenceFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
              },
            },
          },
          'urbanistic-situation-terrain': {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          checks: {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          conditioningFactors: {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          'visit-building-info': {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          buildingState: {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          'valuation-list': {
            access: [],
            enable: [],
            actions: {
              canEdit: [],
            },
          },
          valuations: {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canAdd: [],
              canRecalculate: [],
            },
            'comparison-method': {
              access: [],
              enable: [],
              createComparable: {
                access: ['LicenseeUserRole_Appraiser'],
                enable: [],
                showExternSourceFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
                showLocationFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
                showCadastralReferenceFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
              },
              ListComparable: {
                access: ['LicenseeUserRole_Appraiser'],
                enable: [],
                showSourceFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
                showLocationFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
                showCadastralReferenceFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
              },
            },
            'cost-method': {
              access: [],
              enable: [],
            },
            'discount-cashflow-method': {
              access: [],
              enable: [],
              createComparable: {
                access: [],
                enable: [],
                showExternSourceFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
                showLocationFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
                showCadastralReferenceFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
              },
              ListComparable: {
                access: [],
                enable: [],
                showSourceFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
                showLocationFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
                showCadastralReferenceFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
              },
            },
            'contract-cashflow-method': {
              access: [],
              enable: [],
            },
            'legal-maximum-method': {
              access: [],
              enable: [],
            },
            'other-method': {
              access: [],
              enable: [],
            },
            'dynamic-residual-method': {
              access: ['LicenseeUserRole_Appraiser'],
              enable: ['LicenseeUserRole_Appraiser'],
              createComparable: {
                access: [],
                enable: [],
                showExternSourceFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
                showLocationFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
                showCadastralReferenceFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
              },
              ListComparable: {
                access: [],
                enable: [],
                showExternSourceFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
                showLocationFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
                showCadastralReferenceFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
              },
            },
            'static-residual-method': {
              access: [],
              enable: [],
              createComparable: {
                access: [],
                enable: [],
                showExternSourceFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
                showLocationFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
                showCadastralReferenceFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
              },
              ListComparable: {
                access: [],
                enable: [],
                showSourceFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
                showLocationFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
                showCadastralReferenceFilter: {
                  access: ['LicenseeUserRole_Appraiser'],
                },
              },
            },
            'economic-exploitation-method': {
              access: [],
              enable: [],
            },
            'rustic-estate-exploitation-method': {
              access: [],
              enable: [],
            },
          },
        },
        assets: {
          access: ['LicenseeUserRole_Appraiser'],
          enable: ['LicenseeUserRole_Appraiser'],
          actions: {
            canAddCluster: ['LicenseeUserRole_Appraiser'],
            canAddTerrainCluster: ['LicenseeUserRole_Appraiser'],
            canAddAsset: ['LicenseeUserRole_Appraiser'],
            canAddAnnex: ['LicenseeUserRole_Appraiser'],
            canDelete: ['LicenseeUserRole_Appraiser'],
            canDuplicate: ['LicenseeUserRole_Appraiser'],
            canMakeItMain: ['LicenseeUserRole_Appraiser'],
          },
          location: {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          identification: {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          typology: {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          'visit-typology': {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          'visit-surface-facilities-stays': {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          'visit-building-info': {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          'visit-environment': {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          'visit-terrain-surface': {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          'visit-terrain-features': {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          surfaces: {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          'locality-environment': {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
              canGetAutomaticData: ['LicenseeUserRole_Appraiser'],
            },
          },
          terrainFeatures: {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          'exploitation-description': {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          'rustic-estate-features': {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          'building-terrain': {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          'building-features': {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          'property-features': {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          'urbanistic-situation': {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          'urbanistic-situation-terrain': {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          holding: {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          checks: {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          'valuation-list': {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          valuations: {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canAdd: ['LicenseeUserRole_Appraiser'],
              canRecalculate: ['LicenseeUserRole_Appraiser'],
            },
            'comparison-method': {
              access: ['LicenseeUserRole_Appraiser'],
              enable: ['LicenseeUserRole_Appraiser'],
            },
            'cost-method': {
              access: ['LicenseeUserRole_Appraiser'],
              enable: ['LicenseeUserRole_Appraiser'],
            },
            'discount-cashflow-method': {
              access: ['LicenseeUserRole_Appraiser'],
              enable: ['LicenseeUserRole_Appraiser'],
            },
            'contract-cashflow-method': {
              access: ['LicenseeUserRole_Appraiser'],
              enable: ['LicenseeUserRole_Appraiser'],
            },
            'legal-maximum-method': {
              access: ['LicenseeUserRole_Appraiser'],
              enable: ['LicenseeUserRole_Appraiser'],
            },
            'other-method': {
              access: ['LicenseeUserRole_Appraiser'],
              enable: ['LicenseeUserRole_Appraiser'],
            },
            'dynamic-residual': {
              access: ['LicenseeUserRole_Appraiser'],
              enable: ['LicenseeUserRole_Appraiser'],
            },
            'static-residual': {
              access: ['LicenseeUserRole_Appraiser'],
              enable: ['LicenseeUserRole_Appraiser'],
            },
            'economic-exploitation-method': {
              access: ['LicenseeUserRole_Appraiser'],
              enable: ['LicenseeUserRole_Appraiser'],
            },
            'rustic-estate-exploitation-method': {
              access: ['LicenseeUserRole_Appraiser'],
              enable: ['LicenseeUserRole_Appraiser'],
            },
          },
          conditioningFactors: {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          photos: {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canDownload: ['LicenseeUserRole_Appraiser'],
              canSetAsPrintable: ['LicenseeUserRole_Appraiser'],
              canDelete: ['LicenseeUserRole_Appraiser'],
              canMakeItMain: ['LicenseeUserRole_Appraiser'],
              canAdd: ['LicenseeUserRole_Appraiser'],
            },
          },
          documentation: {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canSetAsPrintable: ['LicenseeUserRole_Appraiser'],
              canDownload: ['LicenseeUserRole_Appraiser'],
              canAdd: ['LicenseeUserRole_Appraiser'],
              canEdit: ['LicenseeUserRole_Appraiser'],
              canDelete: ['LicenseeUserRole_Appraiser'],
            },
          },
          possession: {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          buildingState: {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
        },
        clusters: {
          access: ['LicenseeUserRole_Appraiser'],
          enable: ['LicenseeUserRole_Appraiser'],
          actions: {
            canDelete: ['LicenseeUserRole_Appraiser'],
          },
          detail: {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          valuations: {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canAdd: ['LicenseeUserRole_Appraiser'],
              canRecalculate: ['LicenseeUserRole_Appraiser'],
            },
            'comparison-method': {
              access: ['LicenseeUserRole_Appraiser'],
              enable: ['LicenseeUserRole_Appraiser'],
            },
            'cost-method': {
              access: ['LicenseeUserRole_Appraiser'],
              enable: ['LicenseeUserRole_Appraiser'],
            },
            'discount-cashflow-method': {
              access: ['LicenseeUserRole_Appraiser'],
              enable: ['LicenseeUserRole_Appraiser'],
            },
            'contract-cashflow-method': {
              access: ['LicenseeUserRole_Appraiser'],
              enable: ['LicenseeUserRole_Appraiser'],
            },
            'legal-maximum-method': {
              access: ['LicenseeUserRole_Appraiser'],
              enable: ['LicenseeUserRole_Appraiser'],
            },
            'other-method': {
              access: ['LicenseeUserRole_Appraiser'],
              enable: ['LicenseeUserRole_Appraiser'],
            },
            'dynamic-residual': {
              access: ['LicenseeUserRole_Appraiser'],
              enable: ['LicenseeUserRole_Appraiser'],
            },
            'static-residual': {
              access: ['LicenseeUserRole_Appraiser'],
              enable: ['LicenseeUserRole_Appraiser'],
            },
            'economic-exploitation-method': {
              access: ['LicenseeUserRole_Appraiser'],
              enable: ['LicenseeUserRole_Appraiser'],
            },
            'rustic-estate-exploitation-method': {
              access: ['LicenseeUserRole_Appraiser'],
              enable: ['LicenseeUserRole_Appraiser'],
            },
          },
        },
        terrains: {
          access: ['LicenseeUserRole_Appraiser'],
          enable: ['LicenseeUserRole_Appraiser'],
          actions: {},
          detail: {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
          calculations: {
            access: ['LicenseeUserRole_Appraiser'],
            enable: ['LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['LicenseeUserRole_Appraiser'],
            },
          },
        },
      },
    },
  },
  user: {
    access: ['LicenseeUserRole_Appraiser'],
    enable: ['LicenseeUserRole_Appraiser'],
    pages: {
      access: ['LicenseeUserRole_Appraiser'],
      enable: ['LicenseeUserRole_Appraiser'],
      profile: {
        access: ['LicenseeUserRole_Appraiser'],
        enable: ['LicenseeUserRole_Appraiser'],
        actions: {
          canEdit: ['LicenseeUserRole_Appraiser'],
        },
      },
      preferences: {
        access: ['LicenseeUserRole_Appraiser'],
        enable: ['LicenseeUserRole_Appraiser'],
      },
      incompatibilities: {
        access: ['LicenseeUserRole_Appraiser'],
        enable: ['LicenseeUserRole_Appraiser'],
        actions: {
          canAdd: ['LicenseeUserRole_Appraiser'],
          canDelete: ['LicenseeUserRole_Appraiser'],
          canEdit: ['LicenseeUserRole_Appraiser'],
        },
      },
    },
  },
  invoices: {
    access: ['LicenseeUserRole_Appraiser'],
    enable: ['LicenseeUserRole_Appraiser'],
    pages: {
      access: ['LicenseeUserRole_Appraiser'],
      enable: ['LicenseeUserRole_Appraiser'],
      list: {
        access: ['LicenseeUserRole_Appraiser'],
        enable: ['LicenseeUserRole_Appraiser'],
      },
      detail: {
        access: ['LicenseeUserRole_Appraiser'],
        enable: ['LicenseeUserRole_Appraiser'],
      },
    },
  },
  documents: {
    access: ['LicenseeUserRole_Appraiser'],
    enable: ['LicenseeUserRole_Appraiser'],
    pages: {
      access: ['LicenseeUserRole_Appraiser'],
      enable: ['LicenseeUserRole_Appraiser'],
      list: {
        access: ['LicenseeUserRole_Appraiser'],
        enable: ['LicenseeUserRole_Appraiser'],
      },
      detail: {
        access: ['LicenseeUserRole_Appraiser'],
        enable: ['LicenseeUserRole_Appraiser'],
      },
    },
  },
  absences: {
    access: ['LicenseeUserRole_Appraiser'],
    enable: ['LicenseeUserRole_Appraiser'],
    pages: {
      access: ['LicenseeUserRole_Appraiser'],
      enable: ['LicenseeUserRole_Appraiser'],
      list: {
        access: ['LicenseeUserRole_Appraiser'],
        enable: ['LicenseeUserRole_Appraiser'],
      },
      detail: {
        access: ['LicenseeUserRole_Appraiser'],
        enable: ['LicenseeUserRole_Appraiser'],
      },
    },
  },
  schedule: {
    access: ['LicenseeUserRole_Appraiser'],
    enable: ['LicenseeUserRole_Appraiser'],
  },
  'how-it-works': {
    access: ['LicenseeUserRole_Appraiser'],
    enable: ['LicenseeUserRole_Appraiser'],
  },
  opportunities: {
    access: ['LicenseeUserRole_Appraiser'],
    enable: ['LicenseeUserRole_Appraiser'],
    list: {
      access: ['LicenseeUserRole_Appraiser'],
      enable: ['LicenseeUserRole_Appraiser'],
    },
    detail: {
      access: ['LicenseeUserRole_Appraiser'],
      enable: ['LicenseeUserRole_Appraiser'],
    },
  },
  collaborations: {
    access: ['LicenseeUserRole_Appraiser'],
    enable: ['LicenseeUserRole_Appraiser'],
    add: {
      access: ['LicenseeUserRole_Appraiser'],
      enable: ['LicenseeUserRole_Appraiser'],
    },
    list: {
      access: ['LicenseeUserRole_Appraiser'],
      enable: ['LicenseeUserRole_Appraiser'],
    },
    detail: {
      access: ['LicenseeUserRole_Appraiser'],
      enable: ['LicenseeUserRole_Appraiser'],
    },
    createComparable: {
      access: [],
      enable: [],
      showExternSourceFilter: {
        access: ['LicenseeUserRole_Appraiser'],
      },
      showLocationFilter: {
        access: ['LicenseeUserRole_Appraiser'],
      },
      showCadastralReferenceFilter: {
        access: ['LicenseeUserRole_Appraiser'],
      },
    },
  },
  ClusterTypes: {
    FinishedElements: ['LicenseeUserRole_Appraiser'],
    Terrains: ['LicenseeUserRole_Appraiser'],
    EconomicExploitation: ['LicenseeUserRole_Appraiser'],
    RusticEstate: [],
  },
  AssetUses: {
    Residential: ['LicenseeUserRole_Appraiser'],
    Commercial: ['LicenseeUserRole_Appraiser'],
    Tertiary: ['LicenseeUserRole_Appraiser'],
    Industrial: ['LicenseeUserRole_Appraiser'],
    Annexed: ['LicenseeUserRole_Appraiser'],
    RusticEstate: ['LicenseeUserRole_Appraiser'],
    Terrain: ['LicenseeUserRole_Appraiser'],
    Dotational: ['LicenseeUserRole_Appraiser'],
  },
  AssetStates: {
    Finished: ['LicenseeUserRole_Appraiser'],
    Unfinished: ['LicenseeUserRole_Appraiser'],
  },
  isEconomicExploitationCheckbox: ['LicenseeUserRole_Appraiser'],
  comparables: {
    access: ['LicenseeUserRole_Appraiser'],
    enable: ['LicenseeUserRole_Appraiser'],
    list: {
      access: ['LicenseeUserRole_Appraiser'],
      enable: ['LicenseeUserRole_Appraiser'],
      actions: {
        canFilterBySource: ['LicenseeUserRole_Appraiser'],
      },
    },
    createComparable: {
      access: [],
      enable: [],
      showExternSourceFilter: {
        access: ['LicenseeUserRole_Appraiser'],
      },
      showLocationFilter: {
        access: ['LicenseeUserRole_Appraiser'],
      },
      showCadastralReferenceFilter: {
        access: ['LicenseeUserRole_Appraiser'],
      },
    },
  },
};
