import { BehaviorSubject } from 'rxjs';

export enum ASYNC_BUTTON_STATE {
  IDLE,
  SENDING,
}

export enum ASYNC_BUTTON_CLASS_TYPES {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DANGER = 'danger',
  PRYMARY_INVERSE = 'primary-inverse',
}

export enum ASYNC_BUTTON_SIZES {
  LARGE = 'lg',
  MEDIUM = 'md',
  SMALL = 'sm',
}

export type AsyncButtonOptionsList<T = any> = AsyncButtonOption<T>[];

export interface AsyncButtonOption<T> {
  callback: (arg: T) => void;
  state?: BehaviorSubject<ASYNC_BUTTON_STATE>;
  loadingLabel?: string;
  asyncButton?: boolean;
  buttonTypeClass?: ASYNC_BUTTON_CLASS_TYPES;
  id: string;
  label: string;
}
