import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { takeUntil } from 'rxjs/operators';

export interface GenericSelectorModel {
  label: string;
  items: GenericSelectorItem[];
}

export interface GenericSelectorItem {
  label: string;
  value: any;
  selectedValue?: any;
  selectedLabel?: any;
  group?: string;
}

/**
 * @TODO Mirar si podemos sustituir este componente por el custom dropdown
 */

@Component({
  selector: 'mcore-generic-dropdown',
  templateUrl: 'generic-dropdown.component.html',
  styleUrls: ['generic-dropdown.component.scss'],
})
export class GenericSelectorComponent extends UnsuscriptionHandler implements OnInit {
  public errors;
  public errorKeys;
  public value;

  public selectedItem: GenericSelectorItem;
  public selectedHeaderItem;

  get showValidationMessages(): boolean {
    return this.errorKeys && this.errorKeys.length;
  }

  @Input() form: FormGroup;
  @Input() controlName: string;
  @Input() control: FormControl;
  @Input() onOpen: (selectedItem: GenericSelectorItem, options: GenericSelectorModel[]) => GenericSelectorItem;

  @Input() readonly: boolean;
  @Input() suggested: boolean;
  @Input() label: string;
  @Input() labelPosition: string = 'floating';
  @Input() placeholder: string;
  @Input() options: GenericSelectorModel[];
  @Input() showValue: boolean = true;
  @Input() showLabelInSurfaceSelected = false;

  @Output() changes: EventEmitter<any> = new EventEmitter();

  constructor() {
    super();
  }

  ngOnInit() {
    if (this.form && this.controlName && !this.control) {
      const control = this.form.get(this.controlName) as FormControl;
      this.control = control;
    }
    this.control.valueChanges.pipe(takeUntil(this._componentDestroyed)).subscribe((el) => {
      this.value = el;
      this.errors = this.control.errors;
      this.errorKeys = this.control.errors && Object.keys(this.control.errors);
      this.setSelectedItem();
    });

    this.setSelectedItem();
  }

  setSelectedItem() {
    if (this.control && this.control.value) {
      const groupLabel = this.options.find((el) => el.items.find((item) => item.value === this.control.value)).label;
      const surface = this.options.reduce((acc, curr) => acc.concat(curr.items), []).find((item) => item.value === this.control.value);
      this.selectedItem = {
        group: groupLabel,
        label: surface.label,
        value: surface.value,
        selectedValue: surface.selectedValue,
        selectedLabel: surface.selectedLabel,
      };
    } else {
      this.selectedItem = null;
    }
  }

  public validateControl() {
    if (!this.control.value && this.control.errors) {
      this.control.updateValueAndValidity();
    }
  }

  public async openModal(event) {
    if (this.options.length) {
      this.selectedItem = await this.onOpen(this.selectedItem, this.options);
      if (this.selectedItem && this.selectedItem.value !== this.control.value) {
        this.control.setValue(this.selectedItem && this.selectedItem.value);
        this.changes.emit();
      }
    }
    event.preventDefault();
  }
}
