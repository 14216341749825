export const esLang = {
  cultureCodeLanguage: 'es-ES',
  languageKey: 'es',
  icon: 'es',
  translates: {
    noContent: 'No hay elementos en el listado',

    LANG_selectLanguage: 'Selecciona un idioma',
    LANG_es: 'Español',
    LANG_en: 'Inglés',
    LANG_pt: 'Portugués',

    loading: 'Cargando',

    LOGIN_email: 'email',
    LOGIN_password: 'contraseña',
    LOGIN_confirmPassword: 'repita la contraseña',
    LOGIN_change: 'cambiar',
    LOGIN_login: 'entrar',
    LOGIN_recoverPassword: 'recuperar contraseña',
    LOGIN_recoverMessage: 'Introduce tu email y te enviaremos las instrucciones para reestablecer tu contraseña.',
    LOGIN_recover: 'recuperar',
    LOGIN_backToLogin: 'volver',
    LOGIN_emailHasBeenSend: 'Las instrucciones para reestablecer tu contraseña han sido enviadas a tu email.',

    FORM_VALIDATION_EMAIL_VALIDATION_ERROR: 'El formato de email no es válido',
    FORM_VALIDATION_EMAIL_VALIDATION_ERROR_ALT: 'El formato de email no es válido',
    FORM_VALIDATION_BUDGET_NUMBER_MASK_ERROR: 'El formato debe ser: 999999-999999',
    FORM_VALIDATION_MAX_LENGTH: 'La longitud máxima del campo es de: ',
    FORM_VALIDATION_MIN_LENGTH: 'La longitud mínima del campo es de: ',
    FORM_VALIDATION_REQUIRED: 'Campo requerido',
    FORM_VALIDATION_PATTERN: 'El formato es incorrecto',
    FORM_VALIDATION_MIN: 'El valor mínimo es: ',
    FORM_VALIDATION_MAX: 'El valor máximo es: ',
    FORM_VALIDATION_NOT_MATCH: 'los campos no coinciden',
    FORM_VALIDATION_additionHigherThan: 'Suma superior al 100%',
    FORM_VALIDATION_WRONG_INPUT: 'Su contraseña debe tener al menos 8 caracteres y contener una combinación de letras minúsculas y mayúsculas, números y caracteres especiales (por ejemplo,! # $).',
    FORM_VALIDATION_NOT_SAME: 'los campos no coinciden',
    FORM_VALIDATION_wrongCredentials: 'Usuario o contraseña incorectos',
    FORM_VALIDATION_MAX_ADDITION: 'La suma supera: ',

    SLIDER_CHIPS_latestOrders: 'últimos encargos',

    MENU_profile: 'mi perfil',
    MENU_comparables: 'comparables',
    MENU_personalData: 'mis datos',
    MENU_documents: 'documentos',
    MENU_invoices: 'facturas',
    MENU_orders: 'encargos',
    MENU_exchange: 'oportunidades',
    MENU_requests: 'colaboraciones',
    MENU_contactUs: 'llámanos',
    MENU_how_it_works: '¿Cómo funciona?',
    MENU_logOut: 'cerrar sesión',
    MENU_schedule: 'Agenda',
    MENU_debug: 'debug',
    MENU_updateDocumentationAndIncompatibilitiesMessage: 'Recuerda la obligación de mantener actualizada tu documentación y listado de incompatibilidades en cumplimiento del artículo 13 del R.D. 775/1997.',
    MENU_absences: 'disponibilidad / ausencias',

    APPRAISER_pageTitle: 'mis datos',
    APPRAISER_personalData: 'mis datos',
    APPRAISER_preferences: 'preferencias',
    APPRAISER_incompatibilities: 'incompatibilidades',
    APPRAISER_firstName: 'nombre',
    APPRAISER_lastName: 'apellidos',
    APPRAISER_nationalIdentityDocument: 'DNI/NIE',
    APPRAISER_phoneNumber: 'teléfono',
    APPRAISER_degree: 'titulación',
    APPRAISER_edit: 'editar',
    APPRAISER_maxDistance: 'distancia máxima',
    APPRAISER_receiveNotifications: 'quiero recibir notificaciones',
    APPRAISER_notificationPush: 'notificaciones push',
    APPRAISER_notificationByEmail: 'notificaciones por email',
    APPRAISER_savePreferences: 'guardar preferencias',
    APPRAISER_preferencesMessage: '¡Configura tus preferencias para poder recibir las últimas oportunidades!',
    APPRAISER_personalDataSection: 'datos personales',
    APPRAISER_notificationsSection: 'notificaciones',
    APPRAISER_changePassword: 'cambiar contraseña',
    APPRAISER_password: 'contraseña',
    APPRAISER_confirmPassword: 'confirmar contraseña',
    APPRAISER_visits: 'Visitas',
    APPRAISER_deadlineDate: 'Fecha límite',

    APPRAISER_INVOICE_DATA_title: 'datos de facturación',
    APPRAISER_INVOICE_DATA_legalName: 'nombre o razón social',
    APPRAISER_INVOICE_DATA_nationalIdentityDocument: 'NIF/CIF',
    APPRAISER_INVOICE_DATA_bankAccount: 'cuenta bancaria',
    APPRAISER_INVOICE_DATA_streetType: 'calle',
    APPRAISER_INVOICE_DATA_streetName: 'nombre calle',
    APPRAISER_INVOICE_DATA_streetNumber: 'número',
    APPRAISER_INVOICE_DATA_locality: 'localidad',
    APPRAISER_INVOICE_DATA_province: 'provincia',
    APPRAISER_INVOICE_DATA_postalCode: 'código postal',
    APPRAISER_INVOICE_DATA_country: 'país',
    APPRAISER_INVOICE_DATA_portal: 'portal',
    APPRAISER_INVOICE_DATA_stairs: 'escalera',
    APPRAISER_INVOICE_DATA_gate: 'puerta',
    APPRAISER_INVOICE_DATA_irpfPercentage: 'retención %IRPF en factura',
    APPRAISER_INVOICE_DATA_billingTaxType: 'tipo de impuesto',
    APPRAISER_INVOICE_DATA_ivaPercentage: 'impuesto',

    LOCATION_country: 'país',
    LOCATION_province: 'provincia',
    LOCATION_locality: 'localidad',
    LOCATION_streetNotExists: 'la vía no existe en el callejero',
    LOCATION_streetType: 'tipo vía',
    LOCATION_streetName: 'nombre vía',
    LOCATION_streetNumber: 'número vía',
    LOCATION_postalCode: 'código postal',
    LOCATION_portal: 'portal',
    LOCATION_stairs: 'escalera',
    LOCATION_floor: 'planta',
    LOCATION_gate: 'puerta',
    LOCATION_locationObservations: 'observaciones',
    LOCATION_block: 'bloque',

    MESSAGES_successPOST: 'Añadido correctamente',
    MESSAGES_successPUT: 'Editado correctamente',
    MESSAGES_errorPUT: 'Error al editar',
    MESSAGES_successDELETE: 'Eliminado correctamente',
    MESSAGES_errorDELETE: 'Error al eliminar',
    MESSAGES_successUploadFile: 'Fichero subido correctamente',
    MESSAGES_errorUploadFile: 'Error al subir el fichero',
    MESSAGES_errorPOSTLIST: 'Error al mostrar el listado',
    MESSAGES_errorPOST: 'Error al añadir',
    MESSAGES_successConfirm: 'Confirmado correctamente',
    MESSAGES_errorConfirm: 'Error al confirmar',
    MESSAGES_successReject: 'Rechazado correctamente',
    MESSAGES_errorReject: 'Error al rechazar',
    MESSAGES_requiredField: 'Faltan campos requeridos',
    MESSAGES_the_action_was_successful: 'La acción se ha realizado correctamente',
    MESSAGES_successful: 'Éxito',
    MESSAGES_error: 'error',

    ACTIONS_cancel: 'Cancelar',
    ACTIONS_add: 'Añadir',
    ACTIONS_edit: 'Editar',
    ACTIONS_delete: 'Eliminar',
    ACTIONS_download: 'Descargar',
    ACTIONS_done: 'Hecho',
    ACTIONS_back: 'Volver',
    ACTIONS_back_list: 'Volver al listado',
    ACTIONS_save: 'Guardar',
    ACTIONS_confirm: 'Confirmar',
    ACTIONS_skip: 'Omitir',
    ACTIONS_close: 'Cerrar',
    ACTIONS_reset: 'Reiniciar',
    ACTIONS_reject: 'rechazar',
    ACTIONS_accept: 'Aceptar',
    ACTIONS_continue: 'Continuar',
    ACTIONS_next: 'Siguiente',
    ACTIONS_save_selection: 'Guardar selección',

    INCOMPATIBILITIES_firstName: 'nombre',
    INCOMPATIBILITIES_lastName: 'apellidos',
    INCOMPATIBILITIES_nationalIdentityDocument: 'DNI/NIE',
    INCOMPATIBILITIES_familyRelationship: 'parentesco',
    WITNESS_OwnComparables: 'Comparables Valum',

    APPRAISAL_BASIC_FILTERS: {
      recordIdContains: 'Nº expediente',
      executionToolId: 'Nº referencia interna',
      externalRecordId: 'Nº referencia externa',
    },
    APPRAISAL_STATUS_FILTERS: {
      currentWorkflowSteps: 'Estado',
      purposes: 'Finalidad',
      visitTypes: 'Tipo de visita',
    },
    APPRAISAL_LIMIT_DATES_FILTERS: {
      scheduleVisitLimit: 'Límite agendar visita',
      startVisitLimit: 'Límite empezar visita',
      finishVisitLimit: 'Límite finalizar visita',
      sentReportLimit: 'Límite envío informe',
    },
    APPRAISAL_INCIDENTS_FILTERS: {
      withIncidentsNotResolved: 'Con incidencias sin resolver',
      withIncidents: 'Con incidencias',
    },
    APPRAISAL_INVOICE_FILTERS: {
      isInConfirmedInvoice: 'Tiene factura confirmada',
    },
    EXCHANGE_FILTERS: {
      distanceBetweenPointMaxMeters: 'Distancia máxima',
      exchangeMinFee: 'Tarifa mínima',
      exchangeFinishMaxDateTime: 'Límite envío informe',
    },
    FILTERS: {
      basicFilters: 'Filtros básicos',
      exchangeFilters: 'Filtros oportunidades',
      appraisalState: 'Estado tasación',
      dateLimits: 'Límite de fechas',
      incidents: 'Incidencias',
      invoice: 'Factura',
      addedDate: 'Fecha de creación',
      apply: 'Aplicar',
      reset: 'Reiniciar',
      requestState: 'Estado solicitud',
      typologyFilters: 'Tipología',
      locationFilters: 'Localización',
      featuresFilters: 'Características',
      appraisal: 'Tasación',
    },
    DATES: {
      from: 'Desde',
      to: 'Hasta',

      visitScheduleDeadlineDateTime: 'límite para agendar',
      visitScheduleDateTime: 'fecha visita',
      appraiserAllDataSentDeadlineDateTime: 'límite para enviar informe',
      addedDateTime: 'añadido',
      solvedDateTime: 'resuelto',
      expirationDateTime: 'expiración',
      date: 'fecha',
      allDay: 'cualquier momento del día',
      hour: 'hora',
      chooseADate: 'Escoge una fecha',
      chooseOtherDate: 'O concreta una nueva fecha para la visita',
      confirmDate: 'Confirmar fecha y hora',
      addProposalDate: 'Añadir posible fecha para la visita',
      obtainedDatetime: 'Fecha de obtención',
      sentReportLimit: 'Límite envío informe',
    },

    APPRAISALS: 'encargos',
    APPRAISAL: {
      searchResult: 'Resultado de la búsqueda',
      addIncident: 'Añadir incidencia',
      addComment: 'Añadir comentario',
      editComment: 'Editar comentario',
      finished: 'Terminadas',
      sentToRevision: 'Enviadas a revisión',
      distance: 'Distancia',
      expiresAt: 'Expira el',
      lastOrders: 'últimos encargos',
      scheduleVisitPending: 'Pendiente de agendar',
      visitPending: 'visita pendiente',
      visitPendingFinish: 'Visita pendiente finalizar',
      sentPending: 'pendientes de enviar',
      rejected: 'rechazadas',
      incidents: 'incidencias',
      detail: 'detalle',
      reports: 'informes',
      report: 'informe',
      board: 'tablón',
      info: 'info',
      requestElements: 'bienes a valorar',
      purpose: 'finalidad',
      visitType: 'tipo de visita',
      minimumTypeVisitRequired: 'Tipo de visita mínima exigible',
      deadlines: 'plazos',
      customer: 'cliente',
      prescriptor: 'prescriptor',
      customers: 'clientes',
      prescriptors: 'prescriptores',
      contact: 'contacto',
      incidentResolution: 'resolución',
      revision: 'Revisión',
      request: 'Solicitud',
      assets: 'Activos',
      clusters: 'Agrupaciones',
      comment: 'comentario',
      incidentDescription: 'Descripción',
      incidentTypes: 'tipos de incidencia',
      scheduleVisit: 'Agendar visita',
      editScheduleVisit: 'Editar fecha visita',
      startVisit: 'Empezar visita',
      startVisitMessage: 'Va a empezar la visita. ¿Continuar?',
      finishVisit: 'Finalizar visita',
      finishVisitMessage: 'Va a finalizar la visita. ¿Continuar?',
      sendToRevision: 'Enviar expediente a revisión',
      assignedTechnicalManager: 'Validador asignado',
      validationStarted: 'Validación empezada',
      sendToRevisionMessage: 'Va a enviar a revisión el expediente. ¿Continuar?',
      visitProposedDates: 'Fechas propuestas para la visita',
      scheduleVisitSuccess: 'Visita agendada correctamente',
      scheduleVisitError: 'Error al agendar la visita',
      visitDate: 'Fecha de visita',
      limitScheduleDateTime: 'límite agendar visita',
      limitSendDataDateTime: 'límite envío informe',
      successSendToRevision: 'Expediente enviado a revisión correctamente',
      successFinishStarted: 'Visita finalizada correctamente',
      successVisitStarted: 'Visita empezada correctamente',
      sureToSelectDate: 'La fecha seleccionada está fuera de plazo.',
      importReport: 'Importar informe desde',
      confirmationHeader: '¿Estás seguro?',
      confirmationMessage: 'Se ha copiado correctamente el informe excepto los apartados de documentos y fotagrafías. Por favor, revise y modifique los datos necesarios e incluya la documentación actualizada',
      scheduleDeadLine: 'límite para agendar visita',
      exchangeFinish: 'límite envío informe',
      idufir: 'C. R. U. (IDUFIR)',
      land: '',
      propertyRegistryName: 'registro de la propiedad',
      volume: 'tomo',
      book: 'libro',
      paper: 'folio',
      inscription: 'inscripción',
      fee: 'honorarios',
      executionMode: 'modo de ejecución',
      executedByIvForce: 'ivforce',
      executedByExternalTool: 'herramienta externa',
      registeredPropertyNumber: 'Finca',
      section: 'Folio',
      appraisalOrderType: 'tipo de encargo',
    },
    APPRAISAL_REPORT: {
      openingDate: 'fecha de apertura',
      closingDate: 'fecha de cierre',
      lastEdition: 'Última edición',
      inProgress: 'En proceso',
      closed: 'Cerrado',
      version: 'Versión',
      importAssetStructure: 'Importar estructura a activos',
      commonData: 'Datos comunes del informe',
      clusterToValuate: 'Agrupar para valorar',
      completeForm: 'Completar formulario',
      checkAllAssets: 'Seleccionar todas las unidades',
      clusterValuations: 'Agrupar valoraciones',
      valuateTerrains: 'Valorar terrenos',
      // STEPS
      units: 'unidades',
      selectStep: 'Seleccionar un paso',
      requiredPercent: 'de requeridos',
      completedPercent: 'de completado',
      addAsset: 'Añadir activo',
      seeValuationResume: 'Ver resumen valoraciones',
      valuationResume: 'Resumen valoraciones',
    },
    ASSET_TREE: {
      addAnnex: 'Añadir anejo',
      duplicate: 'Duplicar',
      delete: 'Eliminar',
      cancel: 'Cancelar',
      makePrincipal: 'Hacer principal',
      extractAsset: 'Extraer de la estructura',

      deleteAssetConfirm: 'Eliminar',
      deleteAssetCancel: 'Cancelar',
      deleteAssetSuccess: 'Activo eliminado correctamente',
      deleteAssetError: 'Error al eliminar activo',
      deleteAsset: 'Eliminar activo',
      deleteAssetMessage: 'Al eliminar el activo, eliminarás también todos los anejos vinculados. ¿Continuar?',

      recalculateValuations: 'Recalcular valoraciones',
      notCompleted: 'No completado',
      unComplete: 'Incompleto',
      complete: 'Completo',
      recalculateWorkPlan: 'Recalcular el estado de la obra',
      hasValuations: 'No puedes agrupar este activo ya que tiene métodos de valoración asociados.',
      onlyClusterSameCategory: 'No podrás agrupar elementos mezclados, suelos solo se puede agrupar con suelos, elementos terminados con elementos terminados y explotaciones económicas con explotaciones económicas. No se podrán agrupar elementos No terminados.',
      selectAssetsToCluster: 'Selecciona las unidades que quieres agrupar:',
    },
    STEPS: {
      location: 'Localización',
      identification: 'Identificación',
      typology: 'Tipología',
      surfaces: 'Superficies',
      localityAndEnvironmentReport: 'Localidad, entorno y mercado',
      terrainFeatures: 'Características del terreno',
      buildingTerrain: 'Descripción del terreno',
      buildingFeatures: 'Características del edificio',
      assetFeatures: 'Características del inmueble',
      urbanisticSituation: 'Situación urbanística',
      possession: 'Posesión',
      checks: 'Comprobaciones',
      valuations: 'Valoraciones',
      conditioningWarningsObservations: 'Condicionantes, advertencias y observaciones',
      photos: 'Fotos',
      documentation: 'Documentación',
      surfacesAndAssetFeatures: 'Superficies y características del inmueble',
      surface: 'Superficie',
      LocalityEnvironmentAndBuildingFeatures: 'Características del edificio, localidad, entorno y mercado',
      buildingState: 'Estado de la obra',
      environment: 'Entorno',
    },

    DOCUMENTS: {
      addDocument: 'Añadir documento',
      document: 'documento',
      documents: 'documentos',
      expirationDate: 'fecha de caducidad',
      addedSuccess: 'El documento se ha subido satisfactoriamente',
      deletedSucces: 'El documento se ha borrado satisfactoriamente',
      deleteThisDocument: '¿Estás seguro que quieres eliminar este documento?',
      expirationDateTime: 'Fecha de expiración',
    },

    error: 'Error',

    REPORT_ASSET_TREE_FILTER: {
      registryNumber: 'Finca registral',
      idufir: 'C.R.U. (IDUFIR)',
      cadastralReference: 'Referencia catrastral',
      address: 'Dirección',
      state: 'Estado',
      use: 'Uso',
      typology: 'Tipología',
      subTypology: 'Subtipología',
    },

    INVOICE: {
      invoices: 'facturas',
      actionSuccess: 'La acción se ha relizado satisfactoriamente',
      actionError: 'No se ha podido realizar la acción',
      rejectInvoice: 'Rechazar factura',
      reasonRejectInvoice: 'Por favor, escribe el motivo',
      subtotal: 'subtotal',
      total: 'total',
      irpf: 'irpf',
      iva: 'iva',
      taxPercentageText: 'Base imponible al ',
      invoiceNumberAlertTitle: 'Por favor, introduce el número de factura',
      invoiceNumber: 'Nº de factura',
    },

    REPORT_ASSET: {
      dataSource: 'Origen de datos',
      observations: 'Observaciones',
      mainElements_Structure: 'Estructura',
      mainElements_Deck: 'Cubierta',
      mainElements_Enclosure: 'Cerramientos',
      mainElements_Foundation: 'Cimentación',
      constructionYear: 'Año de construcción',
      lastRehabilitationYear: 'Año de rehabilitación',
      qualityOfBuilding: 'Calidad de las edificaciones',
      conservationState: 'Estado de conservación',
      buildingUse: 'Uso',
      totalFloorsBellowGround: 'Plantas bajo rasante',
      totalFloorsAboveGround: 'Plantas sobre rasante',
      numberOfBuildingAccesses: 'Accesos del edificio',
      currentStateOfBuilding: '',
      streetCategory: 'Categoría de la calle',
      surveillance: 'Vigilancia',
      hasSwimmingPool: 'Piscina',
      hasSportsZone: 'Zona deportiva',
      hasGreenArea: 'Zonas verdes',
      hasElevator: 'Ascensor',
      totalPlotSurface: 'Superficie de la parcela',
    },

    VALUATIONS: {
      observationsOcupation: 'Observaciones relativas a la ocupación',
      observationsNetIncome: 'Observaciones relativas a la renta neta / Información de estimación de los pagos',
      groundRepercussionError: 'Método de coste',
      necessaryConstructionExpensesPercentageRequired: 'Completar "Cálculo en función avance obra"',
      calculationsInProgressWorkPlanTitle: 'Construcción - Costes y gastos',
      constructionCostsTitle: 'Costes de construcción',
      calculationsInProgressWorkPlanConstructionCostTotal: 'Coste de construcción total',
      calculationsInProgressWorkPlanProgressPercentage: 'Avance de la obra %',
      calculationsInProgressWorkPlanConstructionCostCurrent: 'Coste de construcción - Actual',
      calculationsInProgressWorkPlanNecessaryExpenses: 'Gastos necesarios %',
      calculationsInProgressWorkPlanExpensesProgressPercentage: 'Avance de los gastos %',
      calculationsInProgressWorkPlanAdoptedNecessaryExpenses: 'Gastos necesarios adoptados %',
      calculationsInProgressWorkPlanNecessaryExpensesCurrent: 'Gastos necesarios - Actual',
      calculationsInProgressWorkPlanManualEditing: 'Editar porcentaje de avance en los gastos',
      calculationsInProgressWorkPlanFormMessage: 'Los valores de construcción se calculan en base al porcentaje de avance ce la obra. El valor de Coste proviene del repartido en la pestaña de Estado de obra.',
      calculationsInProgressWorkPlanMessage: '* Los gastos necesarios se calculan respecto a los costes de construcción totales de la unidad (tabla "en estado de la obra") considerando el % aplicado por el grado de avance de la obra. Ver detalle en "Cálculo en función avance de obra".',
      calculationsInProgressWorkPlanLink: 'Cálculo en función avance obra',
      costMethodRepercussionCalculateMessage: 'Si modifica el modo de introducir la repercusión, perderá los datos incluidos hasta el momento, ¿desea continuar?',
      summaryTotalValuationsRecalculationMessage: 'Las características del activo han sido modificadas. Debes recalcular las valoraciones para continuar',
      futureValuationTotalValue: 'Valoración total H.E.T',
      currentValuationTotalValue: 'Valoración total actual',
      valorationMethodAdopted: 'Método de valoración',
      insuranceByRoyalDecree: 'Seguro según R.D.',
      insuranceByEco: 'Seguro según ECO',
      valuationTotalValue: 'Valoración total',

      insuranceValorationTypesDropdown: 'Cálculo del valor del seguro respecto a:',

      proposalValue: 'Valor propuesto',
      documents: 'Documentación',
      totalProposalValue: 'Valor propuesto total',
      observations: 'Observaciones',

      surface: 'Superficie',
      selectSurfaceTocontinue: 'Selecciona una superficie para continuar',
      surfaceCommons: 'Superficie construida con comunes',
      surfaceBuiltWithoutCommons: 'Superficie construida sin comunes',
      surfaceUseful: 'Superficie útil',
      cadastral: 'Catrastral',
      checked: 'Comprobada',
      registered: 'Registral',
      usefulRegistralSurface: 'Superficie útil registral',
      usefulCheckedSurface: 'Superficie útil comprobada',
      usefulQualifiedSurface: 'Superficie útil cédula calif.',

      ground: 'Suelo',
      repercussion: 'Repercusión',
      method: 'Método',
      necessaryExpenses: 'Gastos necesarios',
      totalMarketValue: 'Valor total mercado',
      construction: 'Construcción',
      VRB: 'VRB',
      editPercentages: 'Editar porcentajes',
      physicalBuildingDeprecation: 'Dep. física edificio',
      physicalElementDeprecation: 'Dep. física elemento',
      functionalDeprecation: 'Dep. funcional',
      VRN: 'VRN',

      residualStaticCostTitle: 'Residual estático simplificado',
      residualStaticCostMarketValueUnitPrice: 'Valor de mercado ({{currency_per_surfaceUnit}})',
      residualStaticCostConstructionCostUnitPrice: 'Coste de construcción ({{currency_per_surfaceUnit}})',
      residualStaticCostConstructionExpensesUnitPrice: 'Gastos de construcción ({{currency_per_surfaceUnit}})',
      residualStaticCostFinancialExpensesPercentage: 'Gastos financieros (%)',
      residualStaticCostMarketingExpensesPercentage: 'Gastos de comercialización (%)',
      residualStaticCostDeveloperProfitPercentage: 'Beneficios del promotor (%)',
      residualStaticCostKCoeficient: 'K (Coeficiente para pasar a nuevo)',
      total: 'Total',

      qualifiedSurface: 'Superficie calificada',
      legalMaximumValues: 'Valores de máximo legal',
      legalMaximumValue: 'Valor máximo legal',
      legalMaximumUnitValues: 'Valor unitario máximo legal',
      legalMaximumTotalValue: 'Valor total máximo legal',
      legalMaximumGroundValue: 'Valor máximo legal del suelo',
      groundValueLimit: 'Limitación del valor del suelo',
      REA_legalMaximum: 'REA (máximo legal)',
      REA_costMethod: 'REA (método de coste)',
      valueTypes: 'Tipos de valor',
      moreInfo: 'Más información',
      marketValue: 'Valor de mercado',

      contractualData: 'Datos contractuales',
      contractStartDate: 'Fecha de inicio de contracto',
      contractFinishDate: 'Fecha de extinción de contrato',
      nextChargeDate: 'Fecha del siguiente cobro',
      economicalData: 'Datos económicos',
      monthlyGrossIncome: 'Renta bruta mensual',
      monthlyExpenses: 'Gastos mensuales',
      monthlyNetIncome: 'Renta neta mensual',
      updateType: 'Tipo de actualización',
      updateRatePercent: 'Tasa de actualización',
      monthlyEquivalentUpdateRatePercent: 'Tasa de actualización mensual equivalente',
      reversionValue: 'Valor de reversión',
      capitalGainPercent: 'Plusvalía/minusvalía del suelo',
      updatedValues: 'Valores actualizados',
      updatedIncomes: 'Rentas actualizadas',
      updatedReversion: 'Reversión actualizado',
      updatedTotal: 'Total actualizado',

      marketNetIncome: 'Renta neta mercado',
      euroYearSuffix: '{{currency}}/año',
      euroMonthSuffix: '{{currency}}/mes',
      euroM2MonthSuffix: '{{currency_per_surfaceUnit}}/mes',
      activeNetIncome: 'Renta neta activa',
      currentIncome: 'Renta actual',
      date: 'Fecha',
      incomes: 'Renta',
      yearly: 'Anual',
      monthly: 'Mensual',
      marketEconomicRentalData: 'Datos económicos de renta del mercado',
      homogeinizedValue: 'Valor homogeneizado',
      deprecatedHomogeinizedValue: 'Valor homogeneizado depreciado',
      comparable: 'Comparable',
      adjustComparison: 'Comparación ajustada',

      witnesses: 'Testigos',
      witness: 'Testigo',
      witnessList: 'Lista de testigos',
      distance: 'Distancia',
      surfaceM2: 'Superficie ({{surfaceUnit}})',
      homogeinizedCoefficient: 'Coef. Homogeinización',
      weight: 'Peso',
      marketValueEuroPerM2: 'Valor de mercado ({{currency_per_surfaceUnit}})',
      marketValueEuroPerM2Month: 'Valor de mercado ({{currency_per_surfaceUnit}}/mes)',
      finalValueEuroPerM2: 'Valor final ({{currency_per_surfaceUnit}})',
      finalValueEuroPerM2Mont: 'Valor final ({{currency_per_surfaceUnit}}/mes)',
      noWitnessAdded: 'No hay testigos añadidos',

      desoccupation: 'Desocupación',
      latePayment: 'Morosidad',
      annualCapex: 'Capex anual',
      netIncome: 'Renta neta',
      unitaryEuroM2Month: 'Unitaria ({{currency_per_surfaceUnit}}/mes)',
      monthlyEuroMonth: 'Mensual ({{currency}}/mes)',
      annualEuroYear: 'Anual ({{currency}}/año)',
      idlenessMonths: 'Meses de desocupación',
      latePaymentMonths: 'Meses de morosidad',
      IPC: 'IPC',

      clusterData: 'Datos de la agrupación',
      name: 'Nombre',
      adoptedSurface: 'Superficie adoptada',
      reformLastYear: 'Año de reforma',
      use: 'Uso',
      typology: 'Tipología',
      subtypology: 'Subtipología',
      censusSection: 'Sección censal',
      buildingInfo: 'Información del edificio',
      mainUse: 'Uso predominante',
      constructionYear: 'Año de construcción',

      totalLegalMaximumValue: 'Valor total de máximo legal',

      createCluster: 'Crear agrupación',
      clustersValuations: 'Valoración de agrupaciones',
      terrainsValuations: 'Valoración de terrenos',
      clusterItems: 'Unidades de la agrupación',
      clusterSurface: 'Superficie de la agrupación',
      clusterDeleteMessage: '¿Está seguro de querer eliminar esta agrupación?',

      errors: {
        title: 'Errores',
        buildingUseRequired: 'Falta el uso del edificio',
        buildingYearRequired: 'Falta el año de construcción del edificio',
        costMethodRequired: 'El método de coste es necesario',
        residualStaticAbr: 'Es necesario rellenar el método residual estático en el método de coste',
        atLeastOneSurfaceWithValue: 'Es necesario al menos una superficie con valor',
        groundRepercussionError: 'Repercusión del suelo',
        clusterAssetsWithoutSelectedSurfaces: 'Es necesario añadir las superficies en el apartado de Superficies de cada activo y seleccionarlas en el apartado de Unidades.',
        isRevalorationNeeded: 'Es necesario recalcular en Valoraciones',
      },

      recalculateMethod: 'Recalcular métodos',
      recalculate: 'Recalcular',
      current: 'Valoración actual',
      future: 'Valoración H.E.T.',

      homogeneousCoefficientType: 'Tipo de homogenización',
      confirmCoeficientChange: 'Si modifica el tipo de homogenización, perderá los datos relativos a esta incluidos hasta el momento ¿desea continuar?',
      marketPriceUnitValue: 'Valor de mercado ({{currency_per_surfaceUnit}})',
      constructionRehabilitation: 'Año construcción / rehabilitación',
      surfaceTotal: 'Superficie ({{surfaceUnit}})',
      elevatorsCount: 'Nº de Ascensores',
      homogeneousCoefficient: 'Coef. homog. global',
      adoptedUnitValue: 'Valor final ({{currency_per_surfaceUnit}})',
      situation: 'Coef. Situación',
      orientation: 'Coef. Orientación',
      state: 'Coef. Estado',
      height: 'Coef. Altura',
      coefSurface: 'Coef. Superficie',
      morphology: 'Coef. Morfología',
      polyvalence: 'Coef. Polivalencia',
      accessibility: 'Coef. Accesibilidad',
      antiquity: 'Coef. Antiguedad',
      others: 'Coef. Otros',
      header: 'Editar coeficientes de homogenización',
      witnessAddedDate: 'Fecha de creación del testigo',
      witnessAddedDateCreation: 'Fecha de creación',
    },

    TYPOLOGY: {
      hasActiveCultivation: '¿Tiene cultivo activo?',
      isPlot: '¿Es un solar?',
      borrowersHabitualResidence: 'Vivienda habitual del prestatario',
      use: 'uso',
      residenceType: '1º/2º residencia',
      typology: 'tipologia',
      subTypology: 'subtipologia',
      state: 'estado del activo',
      conservationState: 'estado de conservación',
      legalSituation: 'situación legal',
      hasEnergeticCertificate: '¿tiene certificado de eficiencia energética?',
      reformLastYear: 'año de reforma',
      energeticCertificateGrade: 'típos de certificados de eficiencia energética',
      expedient: 'expediente',
      protectionTimeLimit: 'plazo protección',
      provisionalQualificationDateTime: 'fecha calificación provisional',
      definitiveQualificationDateTime: 'fecha calificación definitiva',
      isVPO: 'Es VPO',
      workState: 'Estado de la obra',
      isEconomicExploitation: '¿Es una Explotación Económica?',
      economicExploitationObservations: 'Descripción de la explotación',
    },

    SURFACES: {
      surface: 'Superficie ({{surfaceUnit}})',
      surfaces: 'superficies',
      cadastralSurfaces: 'catastral',
      totalUsefulSurface: 'útil ({{surfaceUnit}})',
      totalBuiltSurfaceWithoutCommonSurfaces: 'construida sin comunes ({{surfaceUnit}})',
      totalBuiltSurfaceWithCommonSurfaces: 'construida con comunes ({{surfaceUnit}})',
      checkedSurfaces: 'comprobada',
      registeredSurfaces: 'registral',
      observations: 'observaciones',
      totalPlotSurface: 'Parcela ({{surfaceUnit}})',
      workPlanTotalSurface: 'Superficie total de la obra ({{surfaceUnit}})',
    },

    NEW_ASSET: {
      addAsset: 'Añadir activo',
      back: 'Volver a listado de activos',
      byLocation: 'por dirección',
      byCadastralReference: 'Por referencia catastral',
      locateAgain: 'Volver a localizar',
      locate: 'Localizar',
      addManually: 'Introducir manualmente',
      cadastralReference: 'Referencia catastral',
      localization: 'localización',
      typology: 'tipología',
      identification: 'identificación',
      surfaceAndBuildingInformation: 'superficie e información del activo',
      surface: 'superficie',
      constructionYear: 'Año de construcción',
      chooseACadastralReference: 'Escoge una referencia catastral',
      chooseIdealistaComparables: 'Escoge los testigos que quieras utilizar',
    },

    IDENTIFICATION: {
      cadastralReference: 'Referencia catastral',
      cadastralReferenceContainerBuilding: 'Referencia catastral del edificio',
      idufir: 'C.R.U: (IDUFIR)',
      registeredPropertyNumber: 'Finca registral',
      propertyRegistrationName: 'Registro de la propiedad',
      volume: 'Tomo',
      book: 'Libro',
      paper: 'Folio',
      section: 'Sección',
      inscription: 'Inscripción',
      observations: 'Observaciones',
    },
    LOCATION_ENVIRONMENT_MARKET: {
      dataSource: 'origen de datos',
      editAutomaticInformation: 'editar información automática',
      environmentPersonalOpinion: 'opinión técnica sobre el entorno del inmueble',
      environment: 'entorno',
      location: 'localidad',
      market: 'mercado',
      featuresMarket: 'características mercado inmobiliario comparable',
      marketMortgageValueDifferences: 'diferencias valor mercado y valor hipotecario',
      offer: 'oferta',
      demand: 'demanda',
      currentPricSaleRental: 'intervalos de precio actuales venta/alquiler',
      demandRevaluationExpectations: 'expectativas oferta-demanda y revalorización',
      jobOccupation: 'ocupación laboral',
      characteristicAntiquity: 'antigüedad característica',
      architecturalHomogeneity: 'homogeneidad arquitectónica',
      infrastructures: 'infraestructuras',
      equipmentAndServices: 'equipamiento y servicios',
      communications: 'comunicaciones',
      parkings: 'aparcamientos',
      buildingDevelopment: 'desarrollo edificatorio',
      renovation: 'renovación',
      localityTypeOfCore: 'tipo de núcleo',
      localityPopulation: 'población',
      localityPopulationEvolution: 'evolución reciente de la población',
      localityUnemploymentRate: 'desempleo',
      localityAvgAnnualIncome: 'renta disponible media anual per cápita',
      censusSectionTypeOfCore: 'tipo de núcleo',
      automaticDataWarning: 'Al guardar el formulario se recuperarán los datos en base a la localización, perdiendo los datos editados manualmente.',
      environmentDescription: 'Descripción del entorno',
      localityMainFeatures: 'Principales características de la localidad',
      environmentSignificance: 'Significación del entorno',
      locationComparedToSameMunicipality: 'Ubicación del inmueble en comparación con otros del mismo municipio',
      locationComparedToSameZone: 'Ubicación del inmueble en comparación con otros de la misma zona',
      locationComparedToSameBuildingElements: 'Ubicación del inmueble dentro del edificio en comparación con otros elementos del mismo edificio',
      pendingInfrastructuresPercentage: 'Porcentaje de infraestructuras pendientes',
      environmentConsolidationDegreePercentage: 'Porcentaje de grado de consolidación del entorno',
    },

    APPRAISAL_REQUESTS: {
      requests: 'solicitudes',
      contactUsTitle: 'las ventajas del colaborador aumentan',
      contentTitle: 'tus presupuestos y pagos a un solo click',
      list1: 'presupuesta tus encargos de tasación en tan solo dos pasos',
      list2: 'conoce la propuesta económica de forma inmediata',
      list3: 'recibe en tu correo un link de pago y el resumen de tu pedido',
      list4: 'tu cliente solo deberá acceder al enlace de pago y podrá elegir el método que más se ajuste a sus preferencias: tarjeta de crédito, transferencia bancaria, etc.',
      list5: 'no debes preocuparte de nada más ¡Tu encargo ya está en marcha!',
      footer: 'te ayudamos a optimizar y rentabilizar aun más tu trabajo.',
      contactUs: '¡contáctanos!',
      readAndAcceptTerms: 'he leído y acepto',
      generalConditions: ' las condiciones generales de venta',
      taxError: 'La tarifa debe ser mayor o igual que la tarifa mínima propuesta',
      totalPriceWithoutTaxes: 'tarifa (sin tasas)',
      withVisit: 'realizo visita',
      withReport: 'ejecuto informe',
      observations: 'observaciones',
      isEnterprise: 'es una empresa',
      name: 'nombre',
      lastName: 'apellidos',
      nationalIdentityDocument: 'DNI/NIE',
      phone: 'teléfono',
      email: 'email',
      orderType: 'tipo de encargo',
      purpose: 'finalidad',
      visitType: 'tipo de visita',
      minimumTypeVisitRequired: 'Tipo de visita mínima exigible',
      witECO: 'ECO 805/2003',
      address: 'dirección',
      postalCode: 'código postal',
      province: 'provincia',
      locality: 'localidad',
      assetData: 'datos del inmueble',
      addAsset: 'añadir activo',
      impossibleToMap: 'No se ha podido mapear la información de catastro, debe rellenarse manualmente algunos datos',
      taxesMessage: '*Las tarifas propuestas son editables siempre y cuando el importe editado sea superior al propuesto.',
      cancelAddAsset: 'cancelar',
      year: 'año',
      priceRange: 'franja de valor estimado',
      taxes: 'tarifa',
      owner: 'datos del titular de la tasación',
      ownerDataMessage: 'Te recordamos que como solicitante de la tasación, eres responsable de haber informado a tu cliente de que sus datos serán cedidos a Instituto de Valoraciones S.A. con la finalidad de ejecutar el servicio contratado',
      appraisalRequestFeatures: 'características del encargo',
      visitProposals: 'Fechas de visita',
      invoiceData: 'datos de facturación',
      copyOwnerData: 'Copiar datos del titular',
      saveRequests: 'Guardar solicitud',
      addRequest: 'añadir solicitud',
      requestDetail: 'Detalle de la solicitud',
      hasAppraisals: 'Tiene valoraciones',
      showAppraisals: 'Ver valoraciones',
      relatedAppraisalList: 'Lista de valoraciones relacionadas',
      cif: 'CIF',
      rateMessage: '* Las tarifas se calculan en función de la tipología y el valor del inmueble. Revisa que la tipología identificada corresponde con la real. De no ser así por favor, introduce el inmueble manualmente.',
      acceptTipologyCommitmentMessage: 'Acepto que el presupuesto será revisado por Instituto de Valoraciones para corroborar que los inmuebles incluidos han sido correctamente identificados en cuanto a su tipología. Si se comprueba que no corresponden con la tipología real, este presupuesto no tendrá validez y el cliente deberá abonar la diferencia para poner en marcha el encargo.',
      visitData: 'datos contacto para la visita',
      visitName: 'nombre apellidos / razón social',
      visitPhone: 'teléfono',
      visitEmail: 'email',
      visitObservations: 'observaciones',
      hasRequestElements: 'Faltan elementos por añadir a la solicitud',
      requestElementsArrayIsValid: 'En alguno de los elementos faltan datos por rellenar',
      proposedDatesFormArrayIsValid: 'Es necesario que reviser las posibles fechas de visita',
      purposeSNB: 'Propósito',
    },
    REPORT_ASSET_PROPERTY_FEATURES: {
      installations: 'instalaciones',
      stays: 'estancias',
      hasHotWater: 'agua caliente',
      hasHeating: 'calefacción',
      hasAirConditioning: 'climatización',
      hasGas: 'gas',
      hasPlumbingAndSanitaryEquipment: 'fontanería y aparatos sanitarios',
      hasIntercom: 'portero automático',
      hasSecurity: 'seguridad',
      hasPhone: 'teléfono',
      hasSanitation: 'saneamiento',
      externalWoodWork: 'carpintería exterior',
      facilityObservations: 'observaciones',
      pavementType: 'tipo de pavimento',
      wallType: 'tipo de pared',
      ceilingType: 'tipo de techo',
      internalWoodWork: 'carpintería interior',
      addStay: 'añadir estancia',
      editStay: 'editar estancia',
      stayType: 'tipo de estancia',
      totalSurface: 'superficie',
      generate: 'generar',
      distribution: 'distribución',
      staysResidentialMessage: 'al menos ha de haber un dormitorio y un baño',
      deleteThisStay: '¿Estas seguro que quieres eliminar esta estancia?',

      propertySize: 'Tamaño del inmueble',
      license: 'Licencia',
      polivalentUse: 'Uso polivalente',
      productAnalysis: 'Análisis de producto',

      hasEquippedKitchen: 'Cocina equipada',
      hasBuiltInWardrobes: 'Armarios empotrados',
      hasAlarmSystem: 'Sistema de alarma',
      hasClosedSecurityCircuit: 'Circuito cerrado de seguridad',
      hotWaterType: 'Agua caliente',
      heatingType: 'Tipo de calefacción',

      localFeatures: 'Características del local',
      facade: 'Fachada (m)',
      depth: 'Fondo (m)',
      clearanceHeight: 'Altura libre (m)',
    },
    WITNESS_DETAIL: {
      supplierInfo: 'Información del proveedor',
      cadastralInformation: 'Información catastral',
      cadastralReference: 'Referencia catastral',
      cadastralSurface: 'Superficie catastral',
      builtSurface: 'Superficie construida',
      plotSurface: 'Superficie de parcela',
      features: 'Características',
      offerValue: 'Valor de oferta',
      reductionNegotiation: 'Reduc. / Negociación',
      offerType: 'Tipo de oferta',
      sourceType: 'Tipo de origen',
      crawledSource: 'Origen del testigo',
      providerUrl: 'URL del proveedor',
      contactInfo: 'Número de contacto',
      constructionYear: 'Año de construcción',
      reformLastYear: 'Año de reforma',
      qualityOfBuilding: 'Calidad de la construcción',
      conservationState: 'Estado de conservación',
      bathroomCount: 'Número de baños',
      bedroomCount: 'Número de habitaciones',
      hasPool: 'Piscina',
      hasSportsZone: 'Zona deportiva',
      hasGreenArea: 'Zonas verdes',
      hasHeating: 'Calefacción',
      hasAirConditioning: 'Climatización',
      hasElevator: 'Ascensor',
      witnessDetail: 'Detalles del testigo',
      hasPaving: 'Pavimentación',
      hasPublicLighting: 'Alumbrado público',
      hasWaterSupply: 'Abastecimiento de agua',
      hasSanitationNetwork: 'Red de saneamiento',
      hasTelephoneNetwork: 'Red de teléfono',
      hasPipedGasNetwork: 'Red de gas canalizado',
      buildeableSurface: 'Superficie edificable',
      observations: 'Observaciones',
      mainUse: 'Uso principal',
      hasFlatTerrain: 'Terreno llano',
      hasWarehouse: 'Almacén',
      hasActiveCrop: 'Cultivo activo',
      hasRolledAccess: 'Acceso rodado',
      hasWaterDistribution: 'Distribución de agua',
    },
    URBAN_SITUATION: {
      dataSource: 'origen de datos',
      currentPlanning: 'planeamiento vigente',
      description: 'descripción',
      currentPlanningAdequacy: '¿adecuación al planeamiento vigente?',
      observations: 'observaciones',
      subjectCorrespondingManagementInitiative: 'Sujeto a quien corresponde la iniciativa de la gestión',
      modeAndManagementSystem: 'Sistema y modo de gestión',
      urbanBurdens: 'Cargas urbanísticas (cesiones, costes de urbanización, obligaciones adquiridas, etc)',
      pendingProcedures: 'Trámites pendientes para suelo finalista',
      executionDeadlinesAndConsequences: 'Plazos de ejecución previstos para el desarrollo urbanístico del terreno. Consecuencias en caso de incumplimiento',
      expropiation: 'Expropiación. Estado del procedimiento expropiatorio. Criterios legales aplicables a su valoración',
      buildability: 'Edificabilidad',
      caracteristicUse: 'Uso característico',
      compatibleUses: 'Usos compatibles',
      prohibitedUses: 'Usos prohibidos',
      maxHeight: 'Altura máxima',
      ocupation: 'Ocupación',
      setBacks: 'Retranqueos',
      minimunPlot: 'Parcela mínima',
      exploitation: 'Aprovechamiento',
      scopeSurface: 'Superficie del ámbito',
      managment: 'Gestión urbanística',
      params: 'Parámetros urbanísticos',
      urbanisticQualificationAndManagement: 'Cualificación y gestión urbanística',
      urbanisticParameters: 'Parámetros urbanísticos',
      isAdequatedToCurrentPlanning: '¿Adecuación al planteamiento vigente?',
    },

    POSSESSION: {
      ocupationState: 'estado de ocupación',
      ocupationType: 'ocupante',
      hasNonexistenceLeasesDocument: 'documento de inexistencias de arendamientos',
      hasContract: '¿Existe contrato?',
      tenureType: 'tipo de tenencia',
      name: 'nombre y apellidos',
      nationalIdentityDocument: 'DNI/NIF',
      percentage: 'porcentaje',
      editTenure: 'editar posesión',
      addTenure: 'añadir posesión',
      deleteMessage: '¿Estas seguro que quieres eliminar esta tenencia?',
      tenurePorcentageMessage: 'La suma de los porcentajes ha de ser 100%',
      requiredField: 'faltan campos requeridos por rellenar',
      tenuresLength: 'faltan añadir tenencias',
      tenureNif: 'DNI/NIF',
    },

    CHECKS: {
      assetChecksDataSource: 'usar datos generales del informe',
      MinimumChecks: 'comprobaciones mínimas',
      ChecksRelatedToAttachedDocumentation: 'comprobaciones relativas a la documentación anexa',
    },

    CONDITIONING_FACTORS: {
      conditions: 'condicionantes',
      warnings: 'advertencias',
      observations: 'observaciones',
      conditionalType: 'Tipo de condicionante',
      warningType: 'Tipo de advertencia',
    },

    TERRAIN_DESCRIPTION_AND_SURFACES: {
      terrainSurfaces: 'superficies del terreno',
      landInfrastructures: 'infraestructuras del terreno',
      cadastralSurfaceTotalPlotSurface: 'registral ({{surfaceUnit}})',
      registeredSurfaceTotalPlotSurface: 'catastral ({{surfaceUnit}})',
      checkedSurfaceTotalPlotSurface: 'comprobada ({{surfaceUnit}})',
      terrainUse: 'calificación del suelo',
      urbanizationState: 'estado de la urbanización',
      morphology: 'morfología',
      topography: 'topografía',
      observations: 'observaciones',
      withPaving: 'pavimentación',
      withStreetLighting: 'alumbrado público',
      withWaterSupply: 'abastecimiento de agua',
      withSanitationNetwork: 'red de saneamiento',
      withTelephoneNetwork: 'red de telefonía',
      withPipedGasNetwork: 'red de gas canalizado',
      infrastructureObservations: 'observaciones',
      terrainDescription: 'Descripción del terreno',
    },
    TERRAIN_FEATURES: {
      pendingInfraestructure: 'Infraestructura pendiente',
      workDonePercentage: 'Porcentaje de obra realizada',
      currentInfraestructure: 'infraestructura existente',
      urbanizationCostsAndPendingCharges: 'costes de urbanización y cargas pendientes',
      existingEdifications: 'edificaciones existentes',
      surfaceToDemolish: 'superficie a demoler ({{surfaceUnit}})',
      accesibility: 'accesibilidad',
      groundLevel: 'nivel de suelo (Normativa ECO/805/2003)',
      planningDevelopment: 'desarrollo de planteamiento',
      managementSystem: 'sistema de gestión',
      managementPhase: 'fases de gestión',
      urbanCoreProximity: 'proximidad respecto el núcleo urbano',
      productToDevelopTypes: 'producto que se prevé desarrollar',
      percentageOfValuedArea: 'porcentaje del ámbito valorado',
      terrainInformationDataSource: 'origen de datos',
      exploitingAboveGround: 'Aprovechamiento sobre rasante ({{surfaceUnit}})',
    },

    APPRAISAL_DOCUMENTS: {
      title: 'documentos',
      addDocument: 'añadir',
      appraisalDocumentsTitle: 'documentos de la tasación',
      assetsDocumentsTitle: 'documentos de los activos',
      document: 'documentación',
      picture: 'fotos de la visita',
      assignToAssets: '¿asignar a activo?',
      remove: 'Eliminar',
      download: 'Descargar',
      selectAssets: 'Seleccionar activos',
    },

    DOCUMENTS_FORM: {
      uploadDocuments: 'subir documentos',
      documentType: 'tipo de documento',
      obtainedDateTime: 'Fecha de emisión',
    },

    EXCHANGES: {
      list: 'Listado de oportunidades',
      assignToMe: '¡Asígnamela!',
      minFee: 'Tarifa mínima',
    },

    REPORT_VISIT_ASSET_SURFACES_FACILITIES_STAYS: {
      surfaces: 'superficie',
      facilities: 'instalaciones',
      stays: 'estancias',
      totalUsefulSurface: 'útil ({{surfaceUnit}})',
      totalBuiltSurfaceWithoutCommonSurfaces: 'construida sin comunes ({{surfaceUnit}})',
      totalBuiltSurfaceWithCommonSurfaces: 'construida con comunes ({{surfaceUnit}})',
    },

    REPORT_VISIT_ASSET_BUILDING_INFO: {
      localityEnvironmentAndMarket: 'localidad, entorno y mercado',
      buildingFeatures: 'características del edificio',
      environmentPersonalOpinion: 'opinión técnica sobre el entorno del inmueble',
      mainElements_Structure: 'estructura',
      mainElements_Deck: 'cubierta',
      mainElements_Enclosure: 'cerramientos',
      mainElements_Foundation: 'cimentación',
      constructionYear: 'año de construcción',
      lastRehabilitationYear: 'año de rehabilitación',
      totalPlotSurface: 'sup. parcela',
      qualityOfBuilding: 'calidad de las edificaciones',
      conservationState: 'estado de conservación',
      buildingUse: 'uso',
      totalFloorsBellowGround: 'Nº plantas bajo rasante',
      totalFloorsAboveGround: 'Nº plantas sobre rasante',
      numberOfBuildingAccesses: 'Nº accesos al edificio',
      streetCategory: 'categoría de la calle',
      surveillance: 'vigilancia',
      hasSportsZone: 'zona deportiva',
      hasSwimmingPool: 'piscina',
      hasGreenArea: 'zonas verdes',
      hasElevator: 'ascensor',
      observations: 'observaciones',
      requiredField: 'faltan campos requeridos por rellenar',
    },

    PICTURES: {
      name: 'nombre',
      description: 'descripción',
      addPhoto: 'Añadir foto',
      cancelReorder: 'Cancelar',
      saveReorder: 'Guardar orden',
      reorderPhotos: 'Reordenar',
      cancelReorderPhotos: 'Cancelar',
      saveReorderPhotos: 'Guardar',
      choosePictureLibrary: 'Galería',
      choosePictureCamera: 'Cámara',
      uploadFotos: 'Subir documentos',
    },

    INPUT_FILE: {
      errorTitle: 'Error',
      errorMessage: 'Formato no soporado',
    },
    BooleanQuestionValues_false: 'No',
    BooleanQuestionValues_true: 'Sí',
    REQUEST_FILTERS: {
      id: 'Identificador',
      containsOwnerFullName: 'Nombre cliente',
      containsOwnerNationalIdentityDocument: 'NIF/CIF cliente',
    },
    DEBUG: {
      confirm: 'Confirmar',
      setDev: 'Set DEV',
      setQA: 'Set QA',
      setPROD: 'Set PROD',
      setPreset: 'Or set a preset endpoint:',
      setEndpoint: 'Set endpoint',
      setCustomEndpoint: 'Set custom endpoint:',
      enpointAdress: 'Endpoint address:',
      cancel: 'Cancelar',
    },

    VALUATION_RESUME: {
      version: 'Versión',
      element: 'Elemento',
      unitValue: 'Valor unitario',
      totalValue: 'Valor total {{currency}}',
      subtotal: 'Subtotal',
      total: 'Total',
    },

    GROUP_VALUATE_isVPO: 'Es VPO',
    GROUP_VALUATE_protected: 'Protegida',
    GROUP_VALUATE_free: 'Libre',
    CLUSTER_TERRAIN: {
      addBuildingHypothesis: 'Añadir hipótesis',
      assigned: 'Asignado',
      assignedTotalValue: 'Asignado total ({{currency}})',
      assignValueToAll: 'Asignar valor a todos',
      biannual: 'Semestral',
      buildingHypothesisValuation: 'Valoración hipótesis',
      byPeriods: 'por periodos',
      calculationMethod: 'Cálculo del método',
      countBuildingElements: 'Nº elementos a construir',
      developerProfitPercent: 'beneficio promotor (%)',
      editBuildingHypothesis: 'Editar hipótesis',
      editExpense: 'Editar gasto',
      editIncome: 'Editar ingreso',
      editParmaters: 'editar parámetros',
      elementSurface: 'Superficie estimada elemento ({{surfaceUnit}})',
      groupBy: 'Agrupar por',
      increaseInExternalFinancing: 'Incremento financiación ajena (%)',
      increaseInExternalFinancingObservations: 'Justificación del valor',
      maxPercentage: 'entre 0-100%',
      monthly: 'Mensual',
      monthlyRate: 'Tasa act. Mensual eq.',
      netExpensesUpdated: 'Gastos Act.',
      observations: 'Observaciones',
      repercussion: 'repercusión',
      riskPremiumPercent: 'prima riesgo',
      terrainMethodChange: 'Al cambiar el método de valoración se borrarán todos los elementos creados de la valoración anterior. ¿Contiuar?',
      total: 'Total',
      totalIncomesExpensesDiff: 'TOTAL (I-G)',
      totals: 'Totales',
      totalUpdated: 'Total Actualizado',
      updated: 'Actualizados',
      updatedExpenses: 'Gastos actualizados',
      updatedIncome: 'Ingresos actualizados',
      updateRate: 'Tipo actualización (%)',
      valorationUnitValue: 'valor de mercado',
      valorationUnitValueProposed: 'Propuesta de valor ({{currency_per_surfaceUnit}})',
      valuationMethod: 'Método valoración',
      weight: 'peso',
      yearly: 'Anual',
      calculateValorationValue: 'Cálculo valor de mercado',
    },
    BUILDING_STATE: {
      chapterBuilding: 'Capítulo de obra',
      observations: 'Observaciones',
      constructionCostsTotalValue: 'Costes de construcción {{currency}}',
      recalculateWarning: 'Recuerda que después de recalcular debes revisar el reparto de activos y guardar los cambios.',
      recalculateBeforeContinue: 'Es necesario recalcular antes de poder editar el formulario',
      distribution: 'Distribución',
      value: 'Valor ({{currency}})',
    },

    surfaceCommons: 'Superficie construida con comunes',
    surfaceUseful: 'Superficie útil',
    surfaceWithoutCommons: 'Superficie construida sin comunes',
    cadastral: 'Catastral',
    checked: 'Comprobada',
    registered: 'Registral',

    COMPARABLES_FILTERS: {
      workflowItemAssetId: 'Nº expediente',
      offerType: 'Tipo de oferta',
      addedMinDateTime: 'Fecha de creación',
      addedMaxDateTime: 'Fecha de creación',
      bathroomCount: 'Nº baños',
      bedroomCount: 'Nº habitaciones',

      hasHotWater: 'agua caliente',
      hasHeating: 'calefacción',
      hasAirConditioning: 'climatización',
      hasGas: 'gas',
      hasPlumbingAndSanitaryEquipment: 'fontanería y aparatos sanitarios',
      hasIntercom: 'portero automático',
      hasSecurity: 'seguridad',
      hasPhone: 'teléfono',
      hasSanitation: 'saneamiento',
      hasSwimmingPool: 'Piscina',
      hasSportsZone: 'Zona deportiva',
      hasGreenArea: 'Zonas verdes',
      hasElevator: 'Ascensor',
      hasPool: 'Piscina',

      maxValorationTotalAdoptedSurface: 'Superficie máx.',
      minValorationTotalAdoptedSurface: 'Superficie min.',
    },

    VALIDATION_MODAL: {
      bodyText: 'Declaro que he leído y comprendido los artículos relativos a las obligaciones de secreto e incompatibilidades de los profesionales a los que hace referencia los artículos 11 y 13 del RD.775/1997, así como el <a href="https://dev-migration.iv.solutions/files/users/IV_Reglamento_Interno_de_Conducta_2020.pdf">reglamento de conducta interno</a>, aceptando ambos para la realización de mi actividad profesional en Instituto de Valoraciones SA. <hr> Me comprometo a informar a Instituto de Valoraciones de cualquier conflicto de interés que se derive de la realización de mi trabajo y a mantener actualizadas mis incompatibilidades.',
      button: 'ACEPTO',
    },

    CHANGE_TEMPORAL_PASSWORD_recoverMessage: 'Su contraseña actual es temporal, por motivos de seguridad ha de cambiarla.',
    ASSETS_totalPlotSurface: 'Superficie de parcela ({{surfaceUnit}})',

    numberAbbreviation: 'Nº',
    currentValuationAbbreviation: 'Actual',
    futureValuationAbbreviation: 'H.E.T.',
    visit: 'Visita',
    incidentAbbreviation: 'INC',

    confirmDeleteTitle: 'Confirmar eliminar',
    confirmDeleteMessage: '¿Está seguro de querer eliminar esta valoración?',
    buildingElementChangeValuationMethodMessage: 'Al cambiar el método de valoración se eliminará el anterior método usado',
    noMoreInfo: 'No hay más información',

    REA: {
      surfaceInCostMethod: 'Superficie del método de coste: ',
    },

    EXCHANGE_TOUR: {
      welcome: '¡Bienvenido!',
      configureMaxDistance: ' Empieza configurando la distancia máxima de la que quieres recibir oportunidades',
      preferencesSaved: 'Preferencias guardadas correctamente',
      assetAddress: 'Dirección del activo',
    },
    EXCHANGE: {
      assignAppraisalInExchangeMessage: 'Con la asignación estás asumiendo la ejecución de la valoración por los honorarios y en el plazo indicado.',
      successfulAssigned: 'Asignada correctamente',
      errorAssigned: 'Ha ocurrido un error, inténtelo de nuevo.',
    },

    invoice: 'Factura',

    TOUR: {
      exitTour: 'Saltar tour',
      canChangePreferences: 'Recuerda que siempre puedes cambiar tus preferencias desde PERFIL',
      canFilterExchanges: 'Puedes filtrar las oportunidades según fecha y tarifa',
      exchangeMoreInfo: '¿Te interesa saber más sobre la oportunidad?',
      clickInCard: 'Haz click sobre la tarjeta',
      hurryUpAndAssign: '... o si todo te encaja, date prisa y ¡Asígnatela!',
      continueWithYourWork: 'Y sigue con el proceso habitual de trabajo',
    },
    CANDEACTIVATE: {
      pendingChanges: 'Hay cambios pendientes',
      pendingChangesMessage: 'Tiene cambios pendientes de confirmar ¿Está seguro que desea salir de esta página?',
      yesWithoutSaving: 'Sí, sin guardar',
      yesSaving: 'Sí, guardando',
    },
    WORKPLAN_everyAssetMustHaveAValidSurface: 'Todos los activos deben tener al menos una superficie con valor',
    EXCHANGE_errorMessage: 'Ha ocurrido un error, inténtelo de nuevo.',
    EXCHANGE_executionMessage: 'Con la asignación estás asumiendo la ejecución de la valoración por los honorarios y en el plazo indicado.',
    EXCHANGE_assignedRight: 'Asignada correctamente',
    COST_METHOD_editFieldsInCurrentValoration: 'El resto de campos sólo se pueden editar desde la pestaña de Valoración actual',
    VALUATION_LIST_residualDynamicInfo: 'El método residual dinámico se añadirá desde el capitulo Estado de la obra',
    VALUATION_LIST_residualDynamicDeleteMessage: 'Este método sólo se puede eliminar desde el método de residual dinámico de datos comunes correspondiente en la pestaña Estado de la obra',

    CLUSTER: {
      clusterData: 'Datos agrupación',
      valuations: 'Valoraciones',
      totalValue: 'Valoración total',
      valuationsSummary: 'Desglose de valoraciones',
      allValuations: 'Todas las valoraciones',
      canNotValuate: 'Este activo no se puede valorar ya que pertenece a una agrupación.',
      assetNotHasSurfaces: 'Es necesario añadir las superficies en el apartado de Superficies del activo.',
      surfacesError: 'Faltan superficies en los activos por seleccionar.',
      valuationSurfaceSelectedMessage: 'La superficie seleccionada se puede modificar en Unidades',
      valuationSurfaceTitle: 'Superficie',
      necessarySaveCluster: 'Es necesario Guardar para recalcular la superficie de la agrupación.',
      necessarySaveClusterValuationsStep: 'Es necesario Guardar en Unidades para recalcular la superficie de la agrupación.',
      addAsset: 'Añadir unidades',
      removeAssetWithParentOrChildrenConfirm: 'Si eliminas esta unidad se eliminarán las unidades vinculadas a ésta. ¿Eliminar?',
      removeAssetConfirm: 'Vas a eliminar esta unidad. ¿Eliminar?',
      selectClusterType: 'Seleccionar tipo de agrupación:',
      selectClusterAssets: 'Seleccionar los activos de la agrupación',
      minClusterAssets: '*La agrupación debe estar formada al menos por dos activos',
      selectableAssets: ' seleccionables',
      noSelectableAssets: 'no seleccionables',
      noItemsSection: 'No hay elementos',
      noItemsToSelect: 'No hay elementos que puedan formar parte de la agrupación. Si considera que tiene algún activo que necesita agrupar puede revisar si existe algún error en la parte de Activos no seleccionables.',
      isEconomicExploit: 'Explotación económica',
      isNotEconomicExploit: 'No explotación económica',
    },

    cadastralSurfaces: 'Catastral',
    registeredSurfaces: 'Registral',
    checkedSurfaces: 'Comprobada',

    totalPlotSurface: 'parcela',
    totalBuiltSurfaceWithoutCommonSurfaces: 'sin comunes',
    totalBuiltSurfaceWithCommonSurfaces: 'con comunes',
    totalUsefulSurface: 'útil',
    COST_METHOD_isUnfinishedMessage: 'La superficie adoptada solo podrá ser modificada en el capitulo de Estado de la Obra.',
    COST_METHOD_recalulateWorkPlan: 'Es necesario recalcular el estado de la obra y asignarle una superficie a este activo.',
    COST_METHOD_totalAdoptedSurface: 'Es necesario definir las superficies adoptadas en el capítulo de Estado de la Obra.',

    CLUSTER_DETAIL_canNotRemoveAssets: 'Imposible eliminar. Debe haber al menos dos unidades por agrupación',

    REPORT_ASSET_report_structure_hasChildrenWithErrors_error: 'Es necesario revisar los errores pertenecientes a los anejos para poder agrupar.',

    noDistributionData: 'No hay distribuciones',

    CLUSTERS_canNotChangeUseToTerrain: 'Este activo pertenece a una agrupación. Si quiere convertirlo en terreno es necesario sacarlo de dicha agrupación',

    EX_economicDataInfoModalTitle: 'Información Datos Económicos',

    last_orders_empty: '¡Hola {{user}}! No tienes nuevos encargos, si echas alguno en falta revisa en el resto de estados: Visita pendiende, Pendiente de enviar...',
  },
};
